@keyframes rotate {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
@media (min-width: 768px) {
    .artspace {
        width: 100%;
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;
    }
    .artspace .title {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        /* margin-top: clamp(6px, 3rem, 30px);
        margin-bottom: clamp(6px, 3rem, 30px); */
    }
    .artspace .title .menu {
        width: 400px;
        height: 6rem;
        max-height: 60px;
        padding-bottom: 1rem;
        background-color: transparent;
        align-items: center;
        border: none;
    }
    .artspace .title .menu li {
        height: 5rem;
        max-height: 50px;
        line-height: normal;
        padding: 0;
        padding-right: clamp(6px, 1.6rem, 16px);
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .artspace .title .menu li::after {
        width: 50%;
    }
    .artspace .title .menu .ant-menu-item-selected > span {
        color: rgba(25, 73, 255, 1);
    }
    .artspace .title .menu .ant-menu-item-selected::after {
        border-color: rgba(25, 73, 255, 1);
    }
    .artspace .title .menu .ant-menu-item-active::after {
        border-color: rgba(25, 73, 255, 1);
    }
    .artspace .title .menu li span {
        font-family: 'MyCustomFont', sans-serif;
        font-weight: 600;
        font-size: clamp(10px, 1.6rem, 16px);
        color: #999999;
        font-style: normal;
    }
    
    .artspace .title .title-but {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .artspace .title .title-but .input {
        width: 50rem;
        max-width: 500px;
        height: 4.6rem;
        max-height: 46px;
        box-shadow: none;
        border-radius: 8px;
        border: 1px solid rgba(208, 208, 208, 1);
        font-size: clamp(10px, 1.6rem, 16px);
        font-family: 'MyCustomFont', sans-serif;
        line-height: normal;
        padding-top: 0;
        padding-bottom: 0;
    }
    .artspace .title .title-but .input .anticon {
        color: rgba(208, 208, 208, 1);
        font-size: clamp(10px, 1.6rem, 16px);
        font-family: 'MyCustomFont', sans-serif;
    }
    .artspace .title .title-but .but {
        width: 8rem;
        max-width: 80px;
        height: 4.6rem;
        max-height: 46px;
        border-radius: 8px;
        border: 1px solid #D0D0D0;
        font-family: 'MyCustomFont', sans-serif;
        font-weight: 500;
        font-size: clamp(10px, 1.6rem, 16px);
        color: #4C5269;
        font-style: normal;
        margin-left: clamp(10px, 2rem, 20px);
    }
    .artspace .title .title-but .but:hover {
        font-weight: 600;
    }
    .artspace .title .title-but .delete-box {
        width: 26.9rem;
        height: 4.6rem;
        max-width: 269px;
        max-height: 46px;
        border-radius: 8px;
        border: 1px solid #D0D0D0;
        margin-left: clamp(10px, 2rem, 20px);
        display: flex;
        justify-content: space-evenly;
        align-items: center;
    }
    .artspace .title .title-but .delete-box > button {
        font-family: 'MyCustomFont', sans-serif;
        font-weight: 500;
        font-size: clamp(10px, 1.6rem, 16px);
        font-style: normal;
        padding: 0;
    }
    .artspace .title .title-but .delete-box > button:hover {
        font-weight: 600;
    }
    .artspace .title .title-but .delete-box > button:nth-of-type(1) {
        color: #EB1818;
    }
    .artspace .title .title-but .delete-box > button:nth-of-type(2),
    .artspace .title .title-but .delete-box > button:nth-of-type(3) {
        color: rgba(76, 82, 105, 1);
    }

    /* 用户相册card */
    .artspace .content {
        width: 100%;
        height: auto;
        margin-top: 2rem;
        margin-bottom: 6rem;
        display: flex;
        flex-wrap: wrap;
        row-gap: clamp(10px, 2.2rem, 22.5px);
        column-gap: clamp(10px, 2.2rem, 22.5px);
    }
    .artspace .content .content-item {
        width: 34.2rem;
        max-width: 342px;
        height: auto;
        border-radius: 16px;
        border: 1px solid rgba(208, 208, 208, 1);
        padding: 16px clamp(6px, 2.2rem, 22px);
        position: relative;
    }
    /* container */
    .artspace .content .content-item .container {
        width: 100%;
        display: flex;
        justify-content: space-between;
        border-radius: 16px;
    }
    .artspace .content-item .container .container-image-box {
        width: 21rem;
        max-width: 210px;
        height: 27rem;
        max-height: 270px;
        border-radius: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
    }
    .artspace .content-item .container .container-image-box .main-image {
        width: 100%;
        height: auto;
    }
    .artspace .content-item .container .container-image-box .main-image-loading {
        width: 100%;
        height: 100%;
    }
    .artspace .content-item .container .main-image > svg {
        width: 40%;
    }
    .artspace .content-item .container .thumbnail-container {
        width: 6.3rem;
        max-width: 63px;
        display: flex;
        flex-direction: column;
        gap: clamp(1px, 1.3rem, 13px);
    }
    .artspace .content-item .container .thumbnail-container .ant-image-mask-info {
        font-size: clamp(3px, 0.8rem, 8px);
        font-family: 'MyCustomFont', sans-serif;
    }
    .artspace .container .thumbnail-container .thumbnail-container-image-box {
        width: 6.3rem;
        max-width: 63px;
        height: 8.1rem;
        max-height: 81px;
        border-radius: 10px;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
    } 
    .artspace .content-item .container .thumbnail {
        width: 100%;
        height: auto;
    }
    .artspace .container .thumbnail-container-image-box .thumbnail-loading {
        width: 100%;
        height: 100%;
    }
    .artspace .container .thumbnail > svg {
        width: 50%;
    }

    /* error loading*/
    .artspace .content-item .loading,
    .artspace .content-item .error {
        width: 100%;
        height: 100%;
        aspect-ratio: 377/371;
        border-radius: 16px;
        display: flex;
        flex-flow: column;
        justify-content: space-between;
    }
    .artspace .content-item .loading .loading-message,
    .artspace .content-item .error .error-message {
        flex: 1;
        width: 100%;
        border-radius: 16px;
        background-color: rgba(242, 242, 242, 1);
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;
    }
    .artspace .content-item .error .error-message > img {
        width: 9rem;
        max-width: 90px;
        height: 6rem;
        max-height: 60px;
        display: block;
    }
    .artspace .content-item .loading .loading-message > div,
    .artspace .content-item .error .error-message > div {
        font-weight: 300;
        margin-top: clamp(8px, 1.4rem, 14px);
        font-size: clamp(8px, 1.4rem, 14px);
        font-family: 'MyCustomFont', sans-serif;
        color: rgba(246, 46, 164, 1);
    }
    .artspace .content-item .loading .loading-action,
    .artspace .content-item .error .error-action {
        margin-top: clamp(10px, 2rem, 20px);
    }
    .artspace .content-item .error .error-action > .but {
        width: 12.8rem;
        max-width: 128px;
        height: 4.2rem;
        max-height: 42px;
        background: rgba(25, 73, 255, 1);
        border-radius: 8px;
        font-weight: 500;
        font-size: clamp(8px, 1.4rem, 14px);
        font-family: 'MyCustomFont', sans-serif;
        color: #FFFFFF;
        margin-left: clamp(10px, 2rem, 20px);
    }
    .artspace .loading .loading-message .loading-message-box {
        position: relative;
    }
    .artspace .loading-message .loading-message-box .loading-img1 {
        width: 7rem;
        max-width: 70px;
        height: 6rem;
        max-height: 60px;
        display: block;
    }
    .artspace .loading-message .loading-message-box .loading-img2 {
        width: 4.4rem;
        max-width: 44px;
        height: 4.4rem;
        max-height: 44px;
        display: block;
        position: absolute;
        top: -30%;
        right: -30%;
        animation: rotate 1s linear infinite;
    }

    .artspace .content .content-item .content-item-box {
        margin-top: 16px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .artspace .content .content-item-box > div {
        font-family: 'MyCustomFont', sans-serif;
        font-weight: 500;
        font-size: clamp(12px, 2rem, 20px);
        color: #000000;
        white-space: pre-line;
    }
    /* TODO */
    .artspace .content .content-item .ant-skeleton-image {
        width: 100%;
        height: 56.8rem;
        max-height: 568px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .artspace .content .content-item .edit-mask {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 16px;
        background-color: rgba(4, 4, 4, 0.40);
    }
    .artspace .content-item .edit-mask .check {
        display: block;
        width: 3rem;
        height: 3rem;
        max-width: 30px;
        max-height: 30px;
        border: 2px solid #FFFFFF;
        border-radius: 50%;
        position: absolute;
        top: 32px;
        right: 32px;
    }
    .artspace .content .content-item .edit-mask img {
        border: none;
    }
    .artspace .content .none-data {
        width: 100%;
        height: 52.8vh;
        display: flex;
        flex-flow: column;
        align-items: center;
        justify-content: center;
    }
    .artspace .content .none-data > img {
        width: 13rem;
        height: 11.7rem;
        max-width: 130px;
        max-height: 117px;
        display: block;
    }
    .artspace .content .none-data > div {
        color: rgba(113, 128, 150, 1);
        font-size: clamp(10px, 1.6rem, 16px);
        font-family: 'MyCustomFont', sans-serif;
        margin-top: clamp(10px, 3rem, 30px);
    }
    .artspace .content .none-data .but {
        width: 18rem;
        height: 4rem;
        max-width: 180px;
        max-height: 40px;
        background: rgba(25, 73, 255, 1);
        font-family: 'MyCustomFont', sans-serif;
        font-weight: 400;
        font-size: clamp(6px, 1.4rem, 14px);
        color: rgba(255, 255, 255, 1);
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: clamp(6px, 2.4rem, 24px);
    }
    .artspace .content .none-data .but:active,
    .artspace .content .none-data .but:hover {
        background: rgba(25,73,255,0.5);
    }
    .artspace .sorter {
        margin-bottom: clamp(20px, 11.2rem, 112px);
    }
    .artspace .sorter li {
        width: 5rem;
        height: 5rem;
        max-width: 50px;
        max-height: 50px;
        background: #FFFFFF;
        border-radius: 10px;
        border: 2px solid #DDDDDD;
    }
    .artspace .sorter li a {
        width: 100%;
        height: 100%;
        font-family: 'MyCustomFont', sans-serif;
        font-size: clamp(10px, 2.2rem, 22px);
        color: rgba(102, 102, 102, 1);
        font-style: normal;
        font-weight: 400;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .artspace .sorter .ant-pagination-item-active {
        background-color: rgba(25, 73, 255, 1);
    }
    .artspace .sorter .ant-pagination-item-active a {
        color: rgba(255, 255, 255, 1);
        
    }
    .delete-modal .ant-modal-content {
        padding: 35px 32px;
    }
    .delete-modal .ant-modal-content .ant-modal-header img {
        display: block;
        width: 38px;
        height: 34px;
        margin-bottom: 26px;
    }
    .delete-modal .ant-modal-content .ant-modal-body > div {
        font-family: 'MyCustomFont', sans-serif;
        font-weight: bold;
        font-size: 16px;
        color: rgba(51, 51, 51, 1);
        margin-bottom: 10px;
    }
    .delete-modal .ant-modal-content .ant-modal-body > div:nth-of-type(2) {
        font-family: 'MyCustomFont', sans-serif;
        font-weight: 400;
        font-size: 12px;
        margin-bottom: 38px;
    }
    .delete-modal .ant-modal-content .ant-modal-footer > button:nth-of-type(1) {
        width: 109px;
        height: 42px;
        border-radius: 21px;
        border: 1px solid #DADADA;
        font-family: 'MyCustomFont', sans-serif;
        font-weight: 400;
        font-size: 14px;
        color: #666666;
    }
    .delete-modal .ant-modal-content .ant-modal-footer > button:nth-of-type(1):hover {
        background-color: rgba(241, 244, 255, 1);
    }
    .delete-modal .ant-modal-content .ant-modal-footer > button:nth-of-type(2) {
        width: 149px;
        height: 42px;
        background: #1949FF;
        border-radius: 21px;
        font-family: 'MyCustomFont', sans-serif;
        font-weight: 400;
        font-size: 14px;
        color: #FFFFFF;
    }
    .delete-modal .ant-modal-content .ant-modal-footer > button:nth-of-type(2):hover {
        background-color: rgba(25, 73, 255, 0.50);
    }
}

@media (max-width: 768px) {
    .artspace {
        width: 100%;
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;
    }
    .artspace .title {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .artspace .title .menu {
        width: 400px;
        /* height: 6rem; */
        height: 0;
        padding-bottom: 1rem;
        background-color: transparent;
        align-items: center;
        border: none;
    }
    .artspace .title .menu li {
        height: 5rem;
        max-height: 50px;
        line-height: normal;
        padding: 0;
        padding-right: clamp(6px, 1.6rem, 16px);
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .artspace .title .menu li::after {
        width: 50%;
    }
    .artspace .title .menu .ant-menu-item-selected > span {
        color: rgba(25, 73, 255, 1);
    }
    .artspace .title .menu .ant-menu-item-selected::after {
        border-color: rgba(25, 73, 255, 1);
    }
    .artspace .title .menu .ant-menu-item-active::after {
        border-color: rgba(25, 73, 255, 1);
    }
    .artspace .title .menu li span {
        font-family: 'MyCustomFont', sans-serif;
        font-weight: 600;
        font-size: clamp(10px, 1.6rem, 16px);
        color: #999999;
        font-style: normal;
    }
    
    .artspace .title .title-but {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .artspace .title .title-but .input {
        width: 50rem;
        max-width: 500px;
        height: 4.6rem;
        max-height: 46px;
        box-shadow: none;
        border-radius: 8px;
        border: 1px solid rgba(208, 208, 208, 1);
        font-size: clamp(10px, 1.6rem, 16px);
        font-family: 'MyCustomFont', sans-serif;
        line-height: normal;
        padding-top: 0;
        padding-bottom: 0;
    }
    .artspace .title .title-but .input .anticon {
        color: rgba(208, 208, 208, 1);
        font-size: clamp(10px, 1.6rem, 16px);
        font-family: 'MyCustomFont', sans-serif;
    }
    .artspace .title .title-but .but {
        width: 4rem;
        height: 2.4rem;
        border-radius: 8px;
        border: 1px solid #D0D0D0;
        font-family: 'MyCustomFont', sans-serif;
        font-weight: 500;
        font-size: 1.4rem;
        color: #4C5269;
        font-style: normal;
        margin-left: clamp(10px, 2rem, 20px);
    }
    .artspace .title .title-but .but:active {
        font-weight: 600;
    }
    .artspace .title .title-but .delete-box {
        width: 16rem;
        height: 2.4rem;
        border-radius: 8px;
        border: 1px solid #D0D0D0;
        margin-left: clamp(10px, 2rem, 20px);
        display: flex;
        justify-content: space-evenly;
        align-items: center;
    }
    .artspace .title .title-but .delete-box > button {
        font-family: 'MyCustomFont', sans-serif;
        font-weight: 500;
        font-size: 1.4rem;
        font-style: normal;
        padding: 0;
    }
    .artspace .title .title-but .delete-box > button:active {
        font-weight: 600;
    }
    .artspace .title .title-but .delete-box > button:nth-of-type(1) {
        color: #EB1818;
    }
    .artspace .title .title-but .delete-box > button:nth-of-type(2),
    .artspace .title .title-but .delete-box > button:nth-of-type(3) {
        color: rgba(76, 82, 105, 1);
    }

    /* 用户相册card */
    .artspace .content {
        width: 100%;
        height: auto;
        margin: 1.8rem 0;
        display: flex;
        flex-wrap: wrap;
        row-gap: 2vw;
        column-gap: 2vw;
    }
    .artspace .content .content-item {
        width: calc(49vw - 14px);
        height: auto;
        border-radius: 8px;
        border: 1px solid rgba(208, 208, 208, 1);
        padding: 8px;
        position: relative;
    }
    .artspace .content .content-item .content-item-mobile-terminal {
        width: 100%;
        height: 100%;
        display: flex;
        flex-flow: column;
        justify-content: space-between;
    }
    /* container */
    .artspace .content .content-item .container {
        width: 100%;
        border-radius: 8px;
    }
    .artspace .content-item .container .container-image-box {
        width: 100%;
        height: 48vw;
        border-radius: 8px;
        margin-bottom: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
    }
    .artspace .content-item .container .container-image-box .main-image {
        width: 100%;
        height: auto;
    }
    .artspace .content-item .container .container-image-box .main-image-loading {
        width: 100%;
        height: 100%;
    }
    .artspace .content-item .container .main-image > svg {
        width: 40%;
    }
    .artspace .content-item .container .thumbnail-container {
        width: 100%;
        display: flex;
        gap: 1vw;
    }
    .artspace .content-item .container .thumbnail-container .ant-image-mask-info {
        font-size: clamp(3px, 0.8rem, 8px);
        font-family: 'MyCustomFont', sans-serif;
    }
    .artspace .container .thumbnail-container .thumbnail-container-image-box {
        width: 14vw;
        height: 16vw;
        border-radius: 8px;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
    } 
    .artspace .content-item .container .thumbnail {
        width: 100%;
        height: auto;
    }
    .artspace .container .thumbnail-container-image-box .thumbnail-loading {
        width: 100%;
        height: 100%;
    }
    .artspace .container .thumbnail > svg {
        width: 50%;
    }

    /* error loading*/
    .artspace .content-item .loading,
    .artspace .content-item .error {
        width: 100%;
        height: 100%;
        aspect-ratio: 152/286;
        border-radius: 8px;
        display: flex;
        flex-flow: column;
        justify-content: space-between;
    }
    .artspace .content-item .loading .loading-message,
    .artspace .content-item .error .error-message {
        flex: 1;
        width: 100%;
        border-radius: 8px;
        background-color: rgba(242, 242, 242, 1);
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;
    }
    .artspace .content-item .error .error-message > img {
        width: 9rem;
        max-width: 90px;
        height: 6rem;
        max-height: 60px;
        display: block;
    }
    .artspace .content-item .loading .loading-message > div,
    .artspace .content-item .error .error-message > div {
        font-weight: 300;
        margin-top: clamp(8px, 1.4rem, 14px);
        font-size: clamp(8px, 1.4rem, 14px);
        font-family: 'MyCustomFont', sans-serif;
        color: rgba(246, 46, 164, 1);
        text-align: center;
    }
    .artspace .content-item .loading .loading-action,
    .artspace .content-item .error .error-action {
        margin-top: clamp(10px, 2rem, 20px);
    }
    .artspace .content-item .error .error-action > .but {
        width: 7rem;
        max-width: 70px;
        height: 3rem;
        max-height: 30px;
        background: rgba(25, 73, 255, 1);
        border-radius: 8px;
        font-weight: 500;
        font-size: clamp(8px, 1.2rem, 12px);
        font-family: 'MyCustomFont', sans-serif;
        color: #FFFFFF;
        margin-left: .6rem;
    }
    .artspace .loading .loading-message .loading-message-box {
        position: relative;
    }
    .artspace .loading-message .loading-message-box .loading-img1 {
        width: 7rem;
        max-width: 70px;
        height: 6rem;
        max-height: 60px;
        display: block;
    }
    .artspace .loading-message .loading-message-box .loading-img2 {
        width: 4.4rem;
        max-width: 44px;
        height: 4.4rem;
        max-height: 44px;
        display: block;
        position: absolute;
        top: -30%;
        right: -30%;
        animation: rotate 1s linear infinite;
    }

    .artspace .content .content-item .content-item-box {
        margin-top: 8px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .artspace .content .content-item-box > div {
        font-family: 'MyCustomFont', sans-serif;
        font-weight: 500;
        font-size: 1rem;
        color: #000000;
        white-space: pre-line;
    }
    /* TODO */
    .artspace .content .content-item .ant-skeleton-image {
        width: 100%;
        height: 56.8rem;
        max-height: 568px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .artspace .content .content-item .edit-mask {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 8px;
        background-color: rgba(4, 4, 4, 0.40);
    }
    .artspace .content-item .edit-mask .check {
        display: block;
        width: 2rem;
        height: 2rem;
        max-width: 20px;
        max-height: 20px;
        border: 2px solid #FFFFFF;
        border-radius: 50%;
        position: absolute;
        top: 16px;
        right: 16px;
    }
    .artspace .content .content-item .edit-mask img {
        border: none;
    }
    .artspace .content .none-data {
        width: 100%;
        height: 52.8vh;
        display: flex;
        flex-flow: column;
        align-items: center;
        justify-content: center;
    }
    .artspace .content .none-data > img {
        width: 13rem;
        height: 11.7rem;
        max-width: 130px;
        max-height: 117px;
        display: block;
    }
    .artspace .content .none-data > div {
        color: rgba(113, 128, 150, 1);
        font-size: clamp(10px, 1.6rem, 16px);
        font-family: 'MyCustomFont', sans-serif;
        margin-top: clamp(10px, 3rem, 30px);
    }
    .artspace .content .none-data .but {
        width: 18rem;
        height: 4rem;
        max-width: 180px;
        max-height: 40px;
        background: rgba(25, 73, 255, 1);
        font-family: 'MyCustomFont', sans-serif;
        font-weight: 400;
        font-size: clamp(6px, 1.4rem, 14px);
        color: rgba(255, 255, 255, 1);
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: clamp(6px, 2.4rem, 24px);
    }
    .artspace .content .none-data .but:active,
    .artspace .content .none-data .but:hover {
        background: rgba(25,73,255,0.5);
    }
    .artspace .sorter {
        margin-bottom: clamp(20px, 11.2rem, 112px);
    }
    .artspace .sorter li {
        width: 5rem;
        height: 5rem;
        max-width: 50px;
        max-height: 50px;
        background: #FFFFFF;
        border-radius: 10px;
        border: 2px solid #DDDDDD;
    }
    .artspace .sorter li a {
        width: 100%;
        height: 100%;
        font-family: 'MyCustomFont', sans-serif;
        font-size: clamp(10px, 2.2rem, 22px);
        color: rgba(102, 102, 102, 1);
        font-style: normal;
        font-weight: 400;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .artspace .sorter .ant-pagination-item-active {
        background-color: rgba(25, 73, 255, 1);
    }
    .artspace .sorter .ant-pagination-item-active a {
        color: rgba(255, 255, 255, 1);
        
    }
    .delete-modal .ant-modal-content {
        padding: 35px 32px;
    }
    .delete-modal .ant-modal-content .ant-modal-header img {
        display: block;
        width: 25px;
        height: 22px;
        margin-bottom: 26px;
    }
    .delete-modal .ant-modal-content .ant-modal-body > div {
        font-family: 'MyCustomFont', sans-serif;
        font-weight: bold;
        font-size: 14px;
        color: rgba(51, 51, 51, 1);
        margin-bottom: 10px;
    }
    .delete-modal .ant-modal-content .ant-modal-body > div:nth-of-type(2) {
        font-family: 'MyCustomFont', sans-serif;
        font-weight: 400;
        font-size: 12px;
        margin-bottom: 38px;
    }
    .delete-modal .ant-modal-content .ant-modal-footer > button:nth-of-type(1) {
        width: 109px;
        height: 42px;
        border-radius: 8px;
        border: 1px solid #DADADA;
        font-family: 'MyCustomFont', sans-serif;
        font-weight: 400;
        font-size: 12px;
        color: #666666;
    }
    .delete-modal .ant-modal-content .ant-modal-footer > button:nth-of-type(1):active {
        background-color: rgba(241, 244, 255, 1);
    }
    .delete-modal .ant-modal-content .ant-modal-footer > button:nth-of-type(2) {
        width: 149px;
        height: 42px;
        background: #1949FF;
        border-radius: 8px;
        font-family: 'MyCustomFont', sans-serif;
        font-weight: 400;
        font-size: 12px;
        color: #FFFFFF;
    }
    .delete-modal .ant-modal-content .ant-modal-footer > button:nth-of-type(2):active {
        background-color: rgba(25, 73, 255, 0.50);
    }
}