.page-loading {
    width: 100%;
    height: 30vh;
    display: flex;
    justify-content: center;
    align-items: end;
}
.page-loading .loading-box {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
}
.page-loading .loading-box > img {
    display: block;
    width: 40px;
    height: 40px;
    animation: rotation 1s linear infinite;
}
.page-loading .loading-box > div {
    font-family: 'MyCustomFont', sans-serif;
    font-size: 16px;
    color: rgba(113, 128, 150, 1);
    margin-top: 10px;
}

@keyframes rotation {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}