.error {
    margin: 0;
    padding: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.error .error-box {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
}
.error .error-box > img {
    display: block;
    width: 369px;
    height: 235px;
    margin-bottom: 37px;
}
.error .error-box > div {
    color: rgba(113, 128, 150, 1);
    font-size: 16px;
    font-family: 'MyCustomFont', sans-serif;
}
.error .error-box > button {
    width: 180px;
    height: 60px;
    font-size: 14px;
    font-family: 'MyCustomFont', sans-serif;
    font-weight: 500;
    padding: 0;
    color: rgba(0, 0, 0, 1);
    background-color: rgba(255, 255, 255, 1);
    border: 1px solid rgba(151, 151, 151, 1);
    box-shadow: none;
    border-radius: 30px;
    margin-top: 37px;
}
.error .error-box > button:disabled {
    background-color: rgba(230, 230, 230, 1);
    color: rgba(0, 0, 0, 1);
}
.error .error-box > button:disabled:hover {
    background-color: rgba(230, 230, 230, 1);
    color: rgba(255, 255, 255, 1);
}
.error .error-box > button:hover {
    background-color: rgba(25, 73, 255, 1);
    color: rgba(255, 255, 255, 1);
}