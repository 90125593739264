.ant-select-dropdown {
    font-family: 'MyCustomFont', sans-serif;
}

@media (min-width: 768px) {
    .content-one {
        width: 192rem;
        max-width: 1920px;
        /* height: 93.1rem;
        max-height: 931px; */
        height: 80rem;
        max-height: 800px;
        margin: 0 auto;
        margin-top: clamp(-100px, -10rem, -57px);
        position: relative;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
    }

    .content-one .content-title {
        color: rgba(0, 0, 0, 1);
        width: 114rem;
        height: 26.6rem;
        max-width: 1140px;
        max-height: 266px;
        font-family: 'MyCustomFont', sans-serif;
        font-weight: 600;
        font-size: clamp(32px, 8rem, 80px);
        text-align: center;
        font-style: normal;
        position: absolute;
        top: clamp(70px, 23.5rem, 235px);
        left: 50%;
        transform: translateX(-50%);
    }

    .content-one .content-select {
        width: 50.4rem;
        height: 6.7rem;
        max-width: 504px;
        max-height: 67px;
        background: #FFFFFF;
        box-shadow: 0px 2px 4px 0px rgba(137, 137, 137, 0.2);
        border-radius: 50px;
        position: absolute;
        top: clamp(200px, 55rem, 550px);
        left: 50%;
        transform: translateX(-50%);
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .content-one .content-select .select {
        margin-left: 2rem;
        width: 25.6rem;
        max-width: 256px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .content-one .content-select .select .img {
        width: 3.6rem;
        height: 3.6rem;
        max-width: 36px;
        max-height: 36px;
    }

    .content-one .content-select .select .ant-select {
        width: 17rem;
        max-width: 130px;
        height: 3.6rem;
        max-height: 36px;
    }

    .content-one .content-select .select .ant-select-selector {
        font-size: clamp(6px, 1.8rem, 18px);
        font-family: 'MyCustomFont', sans-serif;
        border: none;
        box-shadow: none !important;
    }

    .content-one .content-select .but {
        margin-right: 2rem;
        width: 12.4rem;
        max-width: 124px;
        height: 4rem;
        max-height: 40px;
        font-family: 'MyCustomFont', sans-serif;
        font-weight: 500;
        font-size: clamp(6px, 1.8rem, 18px);
        color: #FFFFFF;
        font-style: normal;
        background: rgba(25, 73, 255, 1);
    }

    .content-one .content-select .but:hover {
        background: rgba(25, 73, 255, 0.5);
    }

    .content-one .content-select .but:active {
        background: rgba(25, 73, 255, 0.5);
    }

    .content-one .person1 {
        width: 22rem;
        height: 22rem;
        max-width: 220px;
        max-height: 220px;
        position: absolute;
        top: clamp(80px, 12.4rem, 124px);
        left: 10rem;
    }

    .content-one .person1,
    .content-one .person2,
    .content-one .person3,
    .content-one .person4,
    .content-one .person5,
    .content-one .person6,
    .content-one .person7,
    .content-one .person8 {
        transition: transform 0.3s ease;
    }

    .content-one .person1:hover,
    .content-one .person2:hover,
    .content-one .person3:hover,
    .content-one .person4:hover,
    .content-one .person5:hover,
    .content-one .person6:hover,
    .content-one .person7:hover,
    .content-one .person8:hover {
        transform: scale(1.1);
    }

    .content-one .person2 {
        width: 9.7rem;
        height: 9.7rem;
        max-width: 97px;
        max-height: 97px;
        position: absolute;
        top: clamp(180px, 51.6rem, 516px);
        left: 8.2rem;
    }

    .content-one .person3 {
        width: 18.7rem;
        height: 18.7rem;
        max-width: 187px;
        max-height: 187px;
        position: absolute;
        top: clamp(180px, 45.6rem, 456px);
        left: clamp(80px, 30rem, 400px);
    }

    .content-one .person4 {
        width: 15.3rem;
        height: 15.3rem;
        max-width: 153px;
        max-height: 153px;
        position: absolute;
        top: clamp(300px, 70.4rem, 704px);
        left: 16.4rem;
    }

    .content-one .person5 {
        width: 17.2rem;
        height: 17.2rem;
        max-width: 172px;
        max-height: 172px;
        position: absolute;
        top: clamp(70px, 11.1rem, 111px);
        right: clamp(70px, 15rem, 150px);
    }

    .content-one .person6 {
        width: 10.7rem;
        height: 10.7rem;
        max-width: 107px;
        max-height: 107px;
        position: absolute;
        top: clamp(100px, 35.6rem, 356px);
        right: clamp(20px, 10rem, 100px);
    }

    .content-one .person7 {
        width: 18.7rem;
        height: 18.7rem;
        max-width: 187px;
        max-height: 187px;
        position: absolute;
        top: clamp(180px, 50.5rem, 505px);
        right: clamp(80px, 40rem, 400px);
    }

    .content-one .person8 {
        width: 14.7rem;
        height: 14.7rem;
        max-width: 147px;
        max-height: 147px;
        position: absolute;
        top: clamp(300px, 71.1rem, 711px);
        right: clamp(20px, 20rem, 200px);
    }

    .content-one .mouse {
        width: 2.4rem;
        height: 3.4rem;
        max-width: 24px;
        max-height: 34px;
        position: absolute;
        bottom: 6.7rem;
        left: 50%;
        transform: translateX(-50%);
    }

    .content-one .content-text {
        font-family: 'MyCustomFont', sans-serif;
        position: absolute;
        /* top: clamp(240px, 65rem, 650px); */
        top: clamp(140px, 50rem, 500px);
        left: 50%;
        transform: translateX(-50%);
        color: rgba(153, 153, 153, 1);
        /* color: rgba(0, 0, 0, 1); */
        font-size: clamp(6px, 1.8rem, 18px);
    }

    .content-one .content-activity {
        font-family: 'MyCustomFont', sans-serif;
        position: absolute;
        top: clamp(120px, 46rem, 460px);
        left: 50%;
        transform: translateX(-50%);
        /* color: rgba(153, 153, 153, 1); */
        font-size: clamp(6px, 2rem, 20px);
        background: linear-gradient(45deg, #ff007f, #00bfff, #800080, #ffcc00);
        background-clip: text;
        -webkit-background-clip: text;
        color: transparent;
    }
}

@media (max-width: 768px) {
    .content-one {
        width: 100%;
        aspect-ratio: 375 / 408;
        padding: 0 14px;
        margin-top: clamp(-120px, -6.6rem, -66px);
        position: relative;
        background-repeat: no-repeat;
        background-size: contain;
    }

    .content-one .content-title {
        color: rgba(0, 0, 0, 1);
        width: 18.8rem;
        font-family: 'MyCustomFont', sans-serif;
        font-weight: 600;
        font-size: 1.8rem;
        text-align: center;
        font-style: normal;
        position: absolute;
        top: 10rem;
        left: 50%;
        transform: translateX(-50%);
    }

    .content-one .content-select {
        width: 32rem;
        height: 5.6rem;
        background: #FFFFFF;
        box-shadow: 0px 2px 4px 0px rgba(137, 137, 137, 0.2);
        border-radius: 8px;
        position: absolute;
        top: 28rem;
        left: 50%;
        transform: translateX(-50%);
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .content-one .content-select .select {
        margin-left: 1.6rem;
        width: 8rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .content-one .content-select .select .ant-select {
        width: 12rem;
        height: 3.6rem;
    }

    .content-one .content-select .select .ant-select-selector {
        font-size: 1.4rem;
        font-family: 'MyCustomFont', sans-serif;
        border: none;
        box-shadow: none !important;
    }

    .content-one .content-select .but {
        margin-right: 1.6rem;
        width: 6rem;
        height: 3rem;
        font-family: 'MyCustomFont', sans-serif;
        font-weight: 500;
        font-size: 1.4rem;
        color: #FFFFFF;
        font-style: normal;
        background: rgba(25, 73, 255, 1);
        border-radius: 8px;
    }

    .content-one .content-select .but:active {
        background: rgba(25, 73, 255, 0.5);
    }

    .content-one .person1 {
        width: 9rem;
        height: 9rem;
        position: absolute;
        top: 7rem;
        left: -2.7rem;
    }

    .content-one .person1,
    .content-one .person2,
    .content-one .person3,
    .content-one .person4,
    .content-one .person5,
    .content-one .person6,
    .content-one .person7,
    .content-one .person8 {
        transition: transform 0.3s ease;
    }

    .content-one .person1:active,
    .content-one .person2:active,
    .content-one .person3:active,
    .content-one .person4:active,
    .content-one .person5:active,
    .content-one .person6:active,
    .content-one .person7:active,
    .content-one .person8:active {
        transform: scale(1.1);
    }

    .content-one .person2 {
        display: none;
        width: 8rem;
        height: 8rem;
        position: absolute;
        top: 23.8rem;
        left: 4rem;
    }

    .content-one .person3 {
        display: none;
        width: 6.6rem;
        height: 6.6rem;
        position: absolute;
        top: 32.6rem;
        left: 0;
    }

    /* .content-one .person4 {
        width: 8rem;
        height: 8rem;
        max-width: 80px;
        max-height: 80px;
        position: absolute;
        top: clamp(150px, 35rem, 350px);
        left: clamp(40px, 8rem, 80px);
    } */
    .content-one .person5 {
        width: 7.4rem;
        height: 7.4rem;
        position: absolute;
        top: 7rem;
        right: -0.6rem;
    }

    .content-one .person6 {
        display: none;
        width: 8rem;
        height: 8rem;
        position: absolute;
        top: 25.5rem;
        right: 5.3rem;
    }

    .content-one .person7 {
        display: none;
        width: 5.7rem;
        height: 5.7rem;
        position: absolute;
        top: 34.5rem;
        right: -1.9rem;
    }

    /* .content-one .person8 {
        width: 14.7rem;
        height: 14.7rem;
        max-width: 147px;
        max-height: 147px;
        position: absolute;
        top: clamp(300px, 71.1rem, 711px);
        right: clamp(20px, 20rem, 200px);
    } */
    /* .content-one .mouse {
        width: 2.4rem;
        height: 3.4rem;
        max-width: 24px;
        max-height: 34px;
        position: absolute;
        bottom: 6.7rem;
        left: 50%;
        transform: translateX(-50%);
    } */
    .content-one .content-text {
        font-family: 'MyCustomFont', sans-serif;
        position: absolute;
        width: 100%;
        top: 20rem;
        left: 50%;
        transform: translateX(-50%);
        color: rgba(153, 153, 153, 1);
        font-size: 1.4rem;
        text-align: center;
    }

    .content-one .content-activity {
        font-family: 'MyCustomFont', sans-serif;
        position: absolute;
        width: 100%;
        top: 24rem;
        left: 50%;
        transform: translateX(-50%);
        font-size: 1.2rem;
        text-align: center;
        background: linear-gradient(45deg, #ff007f, #00bfff, #800080, #ffcc00);
        background-clip: text;
        -webkit-background-clip: text;
        color: transparent;
    }
}