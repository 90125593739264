@media (min-width: 768px) {
    .login-intercept-modal {
        min-width: 637px;
    }

    .login-intercept-modal .ant-modal-content {
        padding: 35px 32px;
    }

    .login-intercept-modal .ant-modal-content .ant-modal-header .ant-modal-title {
        font-family: 'MyCustomFont', sans-serif;
        margin-bottom: 26px;
        font-size: 36px;
        color: #1949FF;
    }

    .login-intercept-modal .ant-modal-content .ant-modal-header .ant-modal-title>div {
        display: flex;
        align-items: center;
    }

    .login-intercept-modal .ant-modal-content .ant-modal-header .ant-modal-title>div>div {
        margin-left: 20px;
    }

    .login-intercept-modal .ant-modal-content .ant-modal-body>div {
        font-family: 'MyCustomFont', sans-serif;
        font-weight: bold;
        font-size: 16px;
        color: rgba(51, 51, 51, 1);
        margin-bottom: 10px;
    }

    .login-intercept-modal .ant-modal-content .ant-modal-body>div:nth-of-type(2) {
        font-family: 'MyCustomFont', sans-serif;
        font-weight: 400;
        font-size: 12px;
        margin-bottom: 38px;
    }

    .login-intercept-modal .ant-modal-content .ant-modal-body .ant-checkbox-wrapper {
        font-family: 'MyCustomFont', sans-serif;
        font-weight: 400;
        font-size: 12px;
    }

    .login-intercept-modal .ant-modal-content .ant-modal-body .ant-checkbox-wrapper .ant-checkbox-checked .ant-checkbox-inner {
        background-color: #1949FF;
        border-color: #1949FF;
    }

    .login-intercept-modal .ant-modal-content .ant-modal-footer>button {
        width: 149px;
        height: 42px;
        background: #1949FF;
        border-radius: 21px;
        font-family: 'MyCustomFont', sans-serif;
        font-weight: 400;
        font-size: 14px;
        color: #FFFFFF;
    }

    .login-intercept-modal .ant-modal-content .ant-modal-footer>button:hover {
        background-color: rgba(25, 73, 255, 0.50);
    }
}

@media (max-width: 768px) {
    .login-intercept-modal .ant-modal-content {
        padding: 35px 32px;
    }

    .login-intercept-modal .ant-modal-content .ant-modal-header .ant-modal-title {
        font-family: 'MyCustomFont', sans-serif;
        margin-bottom: 26px;
        font-size: 16px;
        color: #1949FF;
    }

    .login-intercept-modal .ant-modal-content .ant-modal-header .ant-modal-title>div {
        display: flex;
        align-items: center;
    }

    .login-intercept-modal .ant-modal-content .ant-modal-header .ant-modal-title>div>div {
        margin-left: 20px;
    }

    .login-intercept-modal .ant-modal-content .ant-modal-body>div {
        font-family: 'MyCustomFont', sans-serif;
        font-weight: bold;
        font-size: 14px;
        color: rgba(51, 51, 51, 1);
        margin-bottom: 10px;
    }

    .login-intercept-modal .ant-modal-content .ant-modal-body>div:nth-of-type(2) {
        font-family: 'MyCustomFont', sans-serif;
        font-weight: 400;
        font-size: 12px;
        margin-bottom: 38px;
    }

    .login-intercept-modal .ant-modal-content .ant-modal-body .ant-checkbox-wrapper {
        font-family: 'MyCustomFont', sans-serif;
        font-weight: 400;
        font-size: 12px;
    }

    .login-intercept-modal .ant-modal-content .ant-modal-body .ant-checkbox-wrapper .ant-checkbox-checked .ant-checkbox-inner {
        background-color: #1949FF;
        border-color: #1949FF;
    }

    .login-intercept-modal .ant-modal-content .ant-modal-footer>button {
        width: 149px;
        height: 42px;
        background: #1949FF;
        border-radius: 8px;
        font-family: 'MyCustomFont', sans-serif;
        font-weight: 400;
        font-size: 12px;
        color: #FFFFFF;
    }

    .login-intercept-modal .ant-modal-content .ant-modal-footer>button:active {
        background-color: rgba(25, 73, 255, 0.50);
    }
}