@media (min-width: 768px) {
    .app-faceid-box {
        width: 100%;
        height: auto;
        margin-bottom: 6rem;
        display: flex;
        flex-wrap: wrap;
        row-gap: clamp(10px, 4rem, 40px);
        column-gap: clamp(10px, 4rem, 40px);
    }

    /* title */
    .app-faceid-box .app-faceid-header {
        width: 100%;
        height: 1.6rem;
        max-height: 16px;
        font-size: clamp(6px, 1.8rem, 18px);
        font-family: 'MyCustomFont', sans-serif;
        margin: clamp(6px, 3.6rem, 36px) 0 0 0;
        display: flex;
        align-items: center;
    }

    .app-faceid-box .app-faceid-header>img {
        width: 1.8rem;
        height: 1.8rem;
        max-width: 18px;
        max-height: 18px;
        margin-right: clamp(6px, 1rem, 10px);
        cursor: pointer;
    }

    .app-faceid-box .app-faceid-header>div {
        height: 2rem;
        max-height: 20px;
        font-family: 'MyCustomFont', sans-serif;
        font-weight: 400;
        font-size: clamp(6px, 1.8rem, 18px);
        color: #333333;
        display: flex;
        align-items: center;
        font-style: normal;
        margin-right: clamp(6px, 1rem, 10px);
        cursor: pointer;
    }

    .app-faceid-box .app-faceid-header>span {
        margin-right: 3px;
    }

    .app-faceid-box .app-faceid-header>span>span {
        width: 4px !important;
        height: 4px !important;
    }

    .app-faceid-box .content {
        width: 100%;
    }

    .app-faceid-box .content .content-row {
        width: 100%;
        display: flex;
        justify-content: space-between;
    }

    .app-faceid-box .content .content-row .content-row-img {
        width: 60rem;
        aspect-ratio: 310 / 181;
        max-width: 50%;
        position: relative;
        border-radius: 16px;
        overflow: hidden;
        margin: clamp(20px, 4rem, 40px) 0;
        user-select: none;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
    }

    .app-faceid-box .content .content-row .content-row-text {
        margin: clamp(20px, 4rem, 40px) 0;
        padding-left: clamp(20px, 4rem, 40px);
        width: 72rem;
        max-width: 50%;
        user-select: none;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        font-family: 'MyCustomFont', sans-serif;
    }

    .app-faceid-box .content .content-row .content-row-img>img {
        display: block;
        width: 90rem;
        max-width: 100%;
        height: auto;
        position: absolute;
        user-select: none;
        -webkit-user-drag: none;
        pointer-events: none;
    }

    .app-faceid-box .content .content-row .content-row-text>h2 {
        font-size: clamp(26px, 5rem, 50px);
        font-weight: 600;
        color: #2b2b2b;
        margin-bottom: clamp(10px, 3rem, 30px);
    }

    .app-faceid-box .content .content-row .content-row-text>div>p {
        font-size: clamp(6px, 2rem, 20px);
        font-weight: 400;
        color: #2b2b2b;
        margin-bottom: clamp(10px, 3rem, 30px);
    }


    .app-faceid-box .app-faceid-box-item {
        width: 25.6rem;
        max-width: 256px;
        height: auto;
        border-radius: 16px;
        border: 1px solid rgba(208, 208, 208, 1);
        padding: clamp(16px, 2rem, 20px);
        display: flex;
        flex-flow: column;
        justify-content: space-between;
    }

    .app-faceid-box .app-faceid-box-item:hover {
        background-color: rgba(242, 242, 242, 1);
    }

    .app-faceid-box .app-faceid-box-item:hover .app-item-box .app-item-action .but {
        background-color: rgba(25, 73, 255, 1);
        color: rgba(255, 255, 255, 1);
    }

    .app-faceid-box .app-faceid-box-item .app-item {
        position: relative;
        width: 100%;
        aspect-ratio: 198 / 263;
        border-radius: 16px;
        overflow: hidden;
        flex: 1;
    }

    .app-faceid-box .app-faceid-box-item .ant-image {
        width: 100%;
        aspect-ratio: 198 / 263;
        pointer-events: none;
        position: absolute;
    }

    .app-faceid-box .app-faceid-box-item .app-item-img {
        width: 100%;
        height: 100%;
        opacity: 1;
    }

    .app-faceid-box .app-faceid-box-item .app-item-img-two {
        width: 100%;
        height: 100%;
        opacity: 0;
    }

    .app-faceid-box .app-faceid-box-item .app-item:hover .app-item-img {
        opacity: 0;
    }

    .app-faceid-box .app-faceid-box-item .app-item:hover .app-item-img-two {
        opacity: 1;
    }

    .app-faceid-box .app-faceid-box-item .app-item-img,
    .app-faceid-box .app-faceid-box-item .app-item-img-two {
        transition: opacity .5s ease;
    }


    .app-faceid-box .app-faceid-box-item .app-item-box .app-item-styler {
        width: 100%;
        height: 4rem;
        max-height: 40px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .app-faceid-box .app-faceid-box-item .ant-skeleton-image {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .app-faceid-box .app-item-box .app-item-styler>div {
        font-family: 'MyCustomFont', sans-serif;
        font-weight: 500;
        font-size: clamp(6px, 1.4rem, 14px);
        color: #000000;
        text-align: justify;
        font-style: normal;
    }

    .app-faceid-box .app-item-box .app-item-styler img {
        width: 2.3rem;
        height: 2.6rem;
        max-width: 23px;
        max-height: 26px;
        display: block;
        pointer-events: none;
    }

    .app-faceid-box .app-item-box .app-item-action {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .app-faceid-box .app-item-box .app-item-action .but {
        width: 12.7rem;
        height: 4.2rem;
        max-width: 127px;
        max-height: 42px;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgba(250, 250, 250, 1);
        font-family: 'MyCustomFont', sans-serif;
        font-weight: 500;
        font-size: clamp(8px, 1.4rem, 14px);
        color: #000000;
        text-align: justify;
        font-style: normal;
        box-shadow: none;
    }

    .app-faceid-box .app-item-box .app-item-action .but:hover {
        background-color: rgba(25, 73, 255, 1);
        color: rgba(255, 255, 255, 1);
    }

    .app-faceid-box .app-item-box .app-item-action .but:active {
        background-color: rgba(25, 73, 255, 1);
        color: rgba(255, 255, 255, 1);
    }

    .app-faceid-box .app-item-box .app-item-action .app-item-integral {
        display: flex;
        align-items: center;
    }

    .app-faceid-box .app-item-action .app-item-integral>img {
        display: block;
        width: 2rem;
        height: 2rem;
        max-width: 20px;
        max-height: 20px;
        pointer-events: none;
    }

    .app-faceid-box .app-item-action .app-item-integral>div {
        font-family: 'MyCustomFont', sans-serif;
        font-weight: 400;
        font-size: clamp(6px, 1.2rem, 12px);
        color: #000000;
        text-align: justify;
        font-style: normal;
        margin-left: clamp(1px, 0.3rem, 3px);
    }
}

@media (max-width: 768px) {
    .app-faceid-box {
        width: 100%;
        height: auto;
        margin-bottom: 2rem;
        display: flex;
        flex-wrap: wrap;
        row-gap: 2vw;
        column-gap: 2vw;
    }

    /* title */
    .app-faceid-box .app-faceid-header {
        width: 100%;
        height: 1.6rem;
        max-height: 16px;
        font-family: 'MyCustomFont', sans-serif;
        margin: 1.8rem 0;
        display: flex;
        align-items: center;
    }

    .app-faceid-box .app-faceid-header>img {
        width: 1.4rem;
        height: 1.4rem;
        max-width: 14px;
        max-height: 14px;
        margin-right: clamp(6px, 1rem, 10px);
        cursor: pointer;
    }

    .app-faceid-box .app-faceid-header>div {
        height: 2rem;
        font-family: 'MyCustomFont', sans-serif;
        font-weight: 400;
        font-size: clamp(6px, 1.4rem, 14px);
        color: #333333;
        display: flex;
        align-items: center;
        font-style: normal;
        margin-right: clamp(6px, 1rem, 10px);
        cursor: pointer;
    }

    .app-faceid-box .app-faceid-header>span {
        margin-right: 3px;
    }

    .app-faceid-box .app-faceid-header>span>span {
        width: 4px !important;
        height: 4px !important;
    }

    .app-faceid-box .content {
        width: 100%;
    }

    .app-faceid-box .content .content-row {
        width: 100%;
        font-family: 'MyCustomFont', sans-serif;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-flow: column;
    }

    .app-faceid-box .content .content-row .content-row-img {
        width: 100%;
        aspect-ratio: 310 / 181;
        position: relative;
        border-radius: 16px;
        overflow: hidden;
        margin-top: 4rem;
        user-select: none;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
    }

    .app-faceid-box .content .content-row .content-row-text {
        width: 100%;
        margin-top: 4rem;
        user-select: none;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        display: flex;
        flex-flow: column;
        justify-content: space-between;
    }

    .app-faceid-box .content .content-row .content-row-img>img {
        display: block;
        width: 100%;
        height: auto;
        position: absolute;
        user-select: none;
        -webkit-user-drag: none;
        pointer-events: none;
    }

    .app-faceid-box .content .content-row .content-row-text>h2 {
        font-size: 1.8rem;
        font-weight: 600;
        color: #2b2b2b;
        margin: 0;
        margin-bottom: 2rem;
        font-family: 'MyCustomFont', sans-serif;
    }

    .app-faceid-box .content .content-row .content-row-text>div>p {
        font-size: 1.2rem;
        line-height: 1.8rem;
        font-weight: 400;
        color: rgba(153, 153, 153, 1);
        margin: 0;
        margin-bottom: 2rem;
        font-family: 'MyCustomFont', sans-serif;
    }

    .app-faceid-box .content .content-row .content-row-text>div>p:nth-of-type(1) {
        font-size: 1.4rem;
        font-weight: 600;
        color: #2b2b2b;
        font-family: 'MyCustomFont', sans-serif;
    }

    /*list*/
    .app-faceid-box .app-faceid-box-item {
        width: calc(49vw - 14px);
        height: auto;
        border-radius: 8px;
        border: 1px solid rgba(208, 208, 208, 1);
        padding: 8px;
        display: flex;
        flex-flow: column;
        justify-content: space-between;
    }

    .app-faceid-box .app-faceid-box-item:active {
        background-color: rgba(242, 242, 242, 1);
    }

    .app-faceid-box .app-faceid-box-item:active .app-item-box .app-item-action .but {
        background-color: rgba(25, 73, 255, 1);
        color: rgba(255, 255, 255, 1);
    }

    .app-faceid-box .app-faceid-box-item .app-item {
        position: relative;
        width: 100%;
        aspect-ratio: 198 / 263;
        border-radius: 8px;
        overflow: hidden;
        flex: 1;
    }

    .app-faceid-box .app-faceid-box-item .ant-image {
        width: 100%;
        aspect-ratio: 198 / 263;
        pointer-events: none;
        position: absolute;
    }

    .app-faceid-box .app-faceid-box-item .app-item-img {
        width: 100%;
        height: 100%;
        opacity: 1;
    }

    .app-faceid-box .app-faceid-box-item .app-item-img-two {
        width: 100%;
        height: 100%;
        opacity: 0;
    }

    .app-faceid-box .app-faceid-box-item .app-item:hover .app-item-img {
        opacity: 0;
    }

    .app-faceid-box .app-faceid-box-item .app-item:hover .app-item-img-two {
        opacity: 1;
    }

    .app-faceid-box .app-faceid-box-item .app-item-img,
    .app-faceid-box .app-faceid-box-item .app-item-img-two {
        transition: opacity .5s ease;
    }


    .app-faceid-box .app-faceid-box-item .app-item-box .app-item-styler {
        width: 100%;
        height: 4rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .app-faceid-box .app-faceid-box-item .ant-skeleton-image {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .app-faceid-box .app-item-box .app-item-styler>div {
        font-family: 'MyCustomFont', sans-serif;
        font-weight: 500;
        font-size: 1rem;
        color: #000000;
        font-style: normal;
        text-align: justify;
    }

    .app-faceid-box .app-item-box .app-item-styler img {
        width: 2.3rem;
        height: 2.6rem;
        display: block;
        pointer-events: none;
    }

    .app-faceid-box .app-item-box .app-item-action {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .app-faceid-box .app-item-box .app-item-action .but {
        width: 10rem;
        height: 4.2rem;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgba(250, 250, 250, 1);
        font-family: 'MyCustomFont', sans-serif;
        font-weight: 500;
        font-size: 1.2rem;
        color: #000000;
        text-align: justify;
        font-style: normal;
        box-shadow: none;
    }

    .app-faceid-box .app-item-box .app-item-action .but:active {
        background-color: rgba(25, 73, 255, 1);
        color: rgba(255, 255, 255, 1);
    }

    .app-faceid-box .app-item-box .app-item-action .app-item-integral {
        display: flex;
        align-items: center;
    }

    .app-faceid-box .app-item-action .app-item-integral>img {
        display: block;
        width: 1.6rem;
        height: 1.6rem;
        pointer-events: none;
    }

    .app-faceid-box .app-item-action .app-item-integral>div {
        font-family: 'MyCustomFont', sans-serif;
        font-weight: 400;
        font-size: 1rem;
        color: #000000;
        text-align: justify;
        font-style: normal;
        margin-left: clamp(1px, 0.3rem, 3px);
    }
}