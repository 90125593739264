
.share .images {
    background: rgba(239, 238, 238, 1);
    overflow: hidden;
}
/* content header */
.share .images .header .logo {
    display: flex;
    align-items: center;
}
/* content */
.share .images .content .img-one {
    height: auto;
    display: block;
}
.share .images .content .img-two {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column;
}
.share .images .content .img-two img {
    width: 50%;
    height: auto;
    display: block;
}
.share .images .content .img-four {
    display: flex;
    flex-wrap: wrap;
}
/* content footer */
.share .images .footer {
    display: flex;
    justify-content: space-between;
    font-family: 'MyCustomFont', sans-serif;
}
/* but */
.share .but {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px auto;
}
.share .but button {
    background: rgba(25, 73, 255, 1);
    color: #ffffff;
    border: none;
    font-family: 'MyCustomFont', sans-serif;
    font-weight: 500;
    border-radius: 8px;
}
.share .but button:active,
.share .but button:hover {
    background: rgba(25,73,255,0.5) !important;
    color: #ffffff !important;
}
@media (min-width: 768px) {
    .share {
        width: 488px;
        margin: 0 auto;
    }
    .share .images {
        margin-top: 32px;
        border-radius: 16px;
        width: 100%;
        padding: 16px;
    }
    /* content header */
    .share .images .header .logo {
        margin-bottom: 32px;
    }
    .share .images .header .logo .img {
        width: 34px;
        height: 34px;
    }
    .share .images .header .logo > div {
        font-weight: 600;
        font-size: 20px;
        font-family: 'MyCustomFont', sans-serif;
        margin-left: 6px;
    }
    .share .images .header .logo > div:nth-of-type(2) {
        color: rgba(25, 73, 255, 1);
        font-size: 12px;
        align-self: start;
        margin-left: 0;
    }
    /* content */
    .share .images .content img {
        border-radius: 16px;
    }
    .share .images .content .img-one {
        width: calc(100% - 32px);
        margin: 16px;
    }
    .share .images .content .img-four,
    .share .images .content .img-two {
        gap: 32px;
        margin: 0 30px;
    }
    .share .images .content .img-four img {
        width: 182px;
        height: auto;
        display: block;
    }
    /* content footer */
    .share .images .footer {
        margin-top: 32px;
    }
    .share .images .footer > div:nth-of-type(1) {
        flex: 1;
        font-size: 26px;
        display: flex;
        flex-flow: column;
        justify-content: space-between;
        margin-right: 32px;
    }
    .share .images .footer > div:nth-of-type(1) > div:nth-of-type(2) {
        font-size: 10px;
    }
    /* but */
    .share .but button {
        width: 140px;
        height: 46px;
        font-size: 16px;
    }
}
@media (max-width: 768px) {
    .share {
        width: 314px;
        margin: 0 auto;
    }
    .share .images {
        margin-top: 32px;
        border-radius: 8px;
        width: 100%;
        padding: 8px;
    }
    /* content header */
    .share .images .header .logo {
        margin-bottom: 16px;
    }
    .share .images .header .logo .img {
        width: 30px;
        height: 30px;
    }
    .share .images .header .logo > div {
        font-weight: 600;
        font-size: 18px;
        font-family: 'MyCustomFont', sans-serif;
        margin-left: 6px;
    }
    .share .images .header .logo > div:nth-of-type(2) {
        color: rgba(25, 73, 255, 1);
        font-size: 12px;
        align-self: start;
        margin-left: 0;
    }
    /* content */
    .share .images .content img {
        border-radius: 8px;
    }
    .share .images .content .img-one {
        width: calc(100% - 16px);
        margin: 8px;
    }
    .share .images .content .img-four,
    .share .images .content .img-two {
        gap: 16px;
        margin: 0 27px;
    }
    .share .images .content .img-four img {
        width: 114px;
        height: auto;
        display: block;
    }
    /* content footer */
    .share .images .footer {
        margin-top: 16px;
    }
    .share .images .footer > div:nth-of-type(1) {
        flex: 1;
        font-size: 16px;
        display: flex;
        flex-flow: column;
        justify-content: space-between;
        margin-right: 16px;
    }
    .share .images .footer > div:nth-of-type(1) > div:nth-of-type(2) {
        font-size: 8px;
    }
    /* but */
    .share .but button {
        width: 120px;
        height: 24px;
        font-size: 14px;
    }
}