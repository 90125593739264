.app {
    margin-right: calc(100% - 100vw);
}
.app .ant-layout-header {
    height: initial;
    padding: 0;
    color: initial;
    line-height: initial;
    background-color: initial;
}
.app .layout-style {
    overflow: hidden;
    background-color: rgba(255, 255, 255, 1);
}
.app .header-style {
    display: flex;
    align-items: center;
    position: relative;
    z-index: 1000;
}
.app .content-style {
    width: 100%;
    margin: auto;
}
.app .footer-style {
    width: 100%;
    color: rgba(255, 255, 255, 1);
    background-color: rgba(28, 28, 28, 1);
    padding: 0;
}
.hover {
    position: relative;
    top: 0;
    transition: top 150ms ease;
}
.hover:hover {
    top: -2px;
    box-shadow: 0px 8px 4px rgba(130, 130, 130, 1);
}
@media (min-width: 768px) {
    .app .header-style {
        height: 10rem;
        max-height: 100px;
    }
    .app .content-style {
        min-height: calc(100vh - clamp(10px, 29.6rem, 296px));
    }
}
@media (max-width: 768px) {
    .app .header-style {
        width: 100%;
        height: 6.6rem;
        max-height: 66px;
        position: fixed;
        top: 0;
        overflow: hidden;
    }
    .app .content-style {
        min-height: calc(100vh - clamp(10px, 11.4rem, 114px));
        margin-top: clamp(10px, 6.6rem, 66px);
    }
}