.my-input-title-tooltip {
    font-family: 'MyCustomFont', sans-serif;
}

.my-input {
    width: 100%;
}

.my-input .input-case::placeholder {
    color: #999;
    opacity: 0.8;
}

.my-input .my-input-title {
    width: 100%;
    height: 2.2rem;
    min-height: 18px;
    margin: clamp(6px, 1.4rem, 14px) 0;
    font-family: 'MyCustomFont', sans-serif;
}

.my-input .my-input-title span:nth-of-type(1) {
    font-size: clamp(6px, 1.8rem, 18px);
}

.my-input .my-input-title span:nth-of-type(2) {
    font-size: clamp(6px, 1.8rem, 18px);
    color: rgba(128, 128, 128, .5);
    margin-left: 1rem;
    font-family: 'MyCustomFont', sans-serif;
}

.my-input .input-case {
    width: 100%;
    padding-left: 2rem;
    font-family: 'MyCustomFont', sans-serif;
    font-weight: 400;
    font-size: clamp(6px, 1.4rem, 14px);
    border: 1px solid #979797;
    box-shadow: none;
}

@media (min-width: 768px) {
    .my-input .input-case {
        height: 6rem;
        max-height: 60px;
        border-radius: 16px;
    }
}

@media (max-width: 768px) {
    .my-input .input-case {
        height: 4.2rem;
        max-height: 42px;
        border-radius: 8px;
    }
}