@media (min-width: 768px) {
    .recharge-record {
        /* margin-top: clamp(10px, 2rem, 20px); */
    }
    .recharge-record .title-but {
        height: 6rem;
        max-height: 60px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .recharge-record .title-but .but {
        width: 12rem;
        max-width: 120px;
        height: 4.6rem;
        max-height: 46px;
        border-radius: 8px;
        border: 1px solid #D0D0D0;
        font-family: 'MyCustomFont', sans-serif;
        font-weight: 500;
        font-size: clamp(10px, 1.6rem, 16px);
        color: #4C5269;
        font-style: normal;
    }
    .recharge-record .title-but .but:hover {
        color: #4C5269;
        border-color: #D0D0D0;
        font-weight: 600;
    }
    .recharge-record .ant-table-wrapper {
        margin-top: 2rem;
        margin-bottom: 6rem;
    }
    .recharge-record .none-data {
        width: 100%;
        min-height: 60.6vh;
        display: flex;
        justify-content: center;
        flex-flow: column;
        align-items: center;
    }
    .recharge-record .none-data > img {
        width: 11.9rem;
        height: 12.6rem;
        max-width: 119px;
        max-height: 126px;
        display: block;
    }
    .recharge-record .none-data > div {
        color: rgba(113, 128, 150, 1);
        font-size: clamp(10px, 1.6rem, 16px);
        font-family: 'MyCustomFont', sans-serif;
        margin-top: clamp(10px, 3rem, 30px);
    }
    .recharge-record .none-data .but {
        width: 18rem;
        height: 4rem;
        max-width: 180px;
        max-height: 40px;
        background: rgba(25, 73, 255, 1);
        font-family: 'MyCustomFont', sans-serif;
        font-weight: 400;
        font-size: clamp(6px, 1.4rem, 14px);
        color: rgba(255, 255, 255, 1);
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: clamp(6px, 2.4rem, 24px);
    }
    .recharge-record .none-data .but:active,
    .recharge-record .none-data .but:hover {
        background: rgba(25,73,255,0.5);
    }
}

@media (max-width: 768px) {
    .recharge-record .ant-table-wrapper {
        margin: 1.8rem 0;
    }
    .recharge-record .none-data {
        width: 100%;
        min-height: 60.6vh;
        display: flex;
        justify-content: center;
        flex-flow: column;
        align-items: center;
    }
    .recharge-record .none-data > img {
        width: 11.9rem;
        height: 12.6rem;
        display: block;
    }
    .recharge-record .none-data > div {
        color: rgba(113, 128, 150, 1);
        font-size: 1.6rem;
        font-family: 'MyCustomFont', sans-serif;
        margin-top: 3rem;
    }
    .recharge-record .none-data .but {
        width: 18rem;
        height: 4rem;
        background: rgba(25, 73, 255, 1);
        font-family: 'MyCustomFont', sans-serif;
        font-weight: 400;
        font-size: 1.4rem;
        color: rgba(255, 255, 255, 1);
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 2.4rem;
        border-radius: 8px;
    }
    .recharge-record .none-data .but:active,
    .recharge-record .none-data .but:hover {
        background: rgba(25,73,255,0.5);
    }
    .recharge-record .ant-pagination {
        width: 100%;
    }
    .recharge-record .ant-table-wrapper .ant-table-pagination {
        max-width: 100%;
        flex-wrap: nowrap;
    }
}