@media (min-width: 768px) {
    .heart {
        width: 144rem;
        max-width: 1440px;
        height: 100%;
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
        z-index: 999;
    }

    .heart .menu {
        flex: 1;
        height: 100%;
        display: flex;
        align-items: center;
    }

    /* logo */
    .heart .menu .logo {
        display: flex;
        align-items: center;
        cursor: pointer;
    }

    .heart .menu .logo .img {
        width: 3.4rem;
        max-width: 34px;
        height: 3.4rem;
        max-height: 34px;
    }

    .heart .menu .logo>div {
        font-weight: 600;
        font-size: clamp(12px, 2rem, 20px);
        font-family: 'MyCustomFont', sans-serif;
        margin-left: 6px;
    }

    .heart .menu .logo>div:nth-of-type(2) {
        color: rgba(25, 73, 255, 1);
        font-size: clamp(6px, 1.2rem, 12px);
        align-self: start;
        margin-left: 0;
    }

    /* 导航栏 */
    .heart .menu .ant-menu {
        width: 70rem;
        background-color: inherit;
        border-bottom: none;
        margin-left: clamp(30px, 8rem, 80px);
    }

    .heart .menu .ant-menu .ant-menu-item {
        font-size: clamp(12px, 1.6rem, 16px);
        font-family: 'MyCustomFont', sans-serif;
        font-weight: 600;
        color: #000000;
        width: 17rem;
        max-width: 170px;
        height: 4rem;
        max-height: 40px;
        border: none;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba(245, 245, 245, 1);
        border-radius: 20px;
        margin: 0 clamp(10px, 1.5rem, 15px);
    }

    .heart .menu .ant-menu .ant-menu-item:hover {
        background-color: rgba(235, 235, 235, 1);
    }

    .heart .menu .ant-menu .ant-menu-item .popover-but {
        width: 17rem;
        max-width: 170px;
        height: 4rem;
        max-height: 40px;
        border-radius: 20px;
        font-size: clamp(12px, 1.6rem, 16px);
        font-family: 'MyCustomFont', sans-serif;
        font-weight: 600;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .heart .menu .ant-menu .ant-menu-item::after {
        content: none;
    }

    .heart .ant-menu-light .ant-menu-horizontal>.ant-menu-item-selected::after {
        content: none;
    }

    .heart .menu .ant-menu .ant-menu-item:hover {
        color: rgba(25, 73, 255, 1);
    }

    .heart .menu .ant-menu .ant-menu-item:hover #header-button>span {
        color: rgba(25, 73, 255, 1);
    }

    /* .heart .menu .ant-menu .ant-menu-item-selected {
        color: rgba(25, 73, 255, 1);
    } */
    /* .heart .menu .ant-menu .ant-menu-item-selected span span {
        color: rgba(25, 73, 255, 1);
    } */
    .heart .menu .ant-menu .ant-menu-item span span {
        font-size: clamp(12px, 1.6rem, 16px);
        font-family: 'MyCustomFont', sans-serif;
        font-weight: 600;
    }

    .heart .menu .ant-menu .ant-menu-item-selected span span span {
        color: rgba(255, 255, 255, 1);
    }

    /* 登录操作 */
    .heart .userAndMenu {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .heart .userAndMenu .user-box {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .heart .userAndMenu .user-box .residual-integral {
        display: flex;
        align-items: center;
        margin-right: clamp(10px, 4rem, 40px);
    }

    .heart .userAndMenu .user-box .residual-integral>div:nth-of-type(1) {
        font-weight: 500;
        font-size: clamp(12px, 1.6rem, 16px);
        font-family: 'MyCustomFont', sans-serif;
    }

    .heart .userAndMenu .user-box .residual-integral>img {
        display: block;
        width: 2rem;
        max-width: 20px;
        height: 2rem;
        max-height: 20px;
        margin: 0 3px;
        position: relative;
        top: 2px;
    }

    .heart .userAndMenu .user-box .residual-integral>div:nth-of-type(2) {
        font-weight: 500;
        font-size: clamp(12px, 1.4rem, 14px);
        font-family: 'MyCustomFont', sans-serif;
    }

    /* share */
    .heart .userAndMenu .share-but {
        font-weight: 600;
        font-size: clamp(6px, 1.6rem, 16px);
        font-family: 'MyCustomFont', sans-serif;
        text-align: right;
        font-style: normal;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: color 0.3s ease;
        border-radius: 20px;
        width: clamp(6px, 15rem, 150px);
        height: clamp(6px, 4rem, 40px);
        margin-right: 16px;
    }

    .heart .userAndMenu .user {
        display: flex;
        align-items: center;
        cursor: pointer;
        background-color: rgba(245, 245, 245, 1);
        border-radius: 22px;
    }

    .heart .userAndMenu .user>.img {
        width: 4.4rem;
        max-width: 44px;
        height: 4.4rem;
        max-height: 44px;
        background-color: rgba(168, 107, 204, 1);
        color: rgba(255, 255, 255, 1);
        margin-right: clamp(5px, 1.3rem, 13px);
    }

    .heart .userAndMenu .user .user-name {
        padding-right: clamp(6px, 2rem, 20px);
        font-weight: 600;
        font-size: clamp(10px, 1.4rem, 14px);
        font-family: 'MyCustomFont', sans-serif;
        color: #333333;
        font-style: normal;
        white-space: nowrap;
    }

    .heart .userAndMenu .login {
        font-weight: 600;
        font-size: clamp(12px, 1.6rem, 16px);
        font-family: 'MyCustomFont', sans-serif;
        background-color: rgba(25, 73, 255, 1);
        color: rgba(255, 255, 255, 1);
        text-align: right;
        font-style: normal;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: color 0.3s ease;
        border-radius: 20px;
        width: clamp(6px, 12rem, 120px);
        height: clamp(6px, 4rem, 40px);
    }

    .heart .userAndMenu .login:hover {
        color: rgba(255, 255, 255, 1);
        background: rgba(25, 73, 255, 0.5);
    }

    .heart .userAndMenu .login:active {
        background: rgba(25, 73, 255, 0.5);
    }

    .heart .userAndMenu .signup {
        width: 11.3rem;
        max-width: 113px;
        min-width: 70px;
        height: 4.4rem;
        max-height: 44px;
        min-height: 26px;
        border-radius: clamp(13px, 2.2rem, 22px);
        font-family: 'MyCustomFont', sans-serif;
        font-weight: 500;
        font-size: clamp(14px, 1.6rem, 20px);
        font-style: normal;
        color: rgba(255, 255, 255, 1);
        background-color: rgba(13, 12, 34, 1);
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .heart .userAndMenu .signup:hover {
        font-weight: 600;
        background-color: rgba(13, 12, 34, 0.9);
    }

    .heart .userAndMenu .signup:active {
        font-weight: 600;
        background-color: rgba(13, 12, 34, 0.9);
    }

    .ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item {
        font-family: 'MyCustomFont', sans-serif;
        font-weight: 500;
        font-size: clamp(10px, 1.4rem, 14px);
        border-radius: 8px;
        text-align: justify;
        font-style: normal;
    }

    .ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item:hover {
        background-color: rgba(245, 245, 245, 1);
    }

    .ant-popover .ant-popover-inner {
        background: #FFFFFF;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
        border-radius: 8px;
        border: 1px solid #E9E9E9;
        padding: clamp(10px, 3rem, 30px);
        position: relative;
        top: clamp(6px, 1.4rem, 14px);
    }

    .popover-card {
        width: 51.2rem;
        max-width: 512px;
    }

    .popover-card>div {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .popover-card>div:nth-of-type(2) {
        margin-top: clamp(10px, 3rem, 30px);
    }

    .popover-card>div>div {
        flex: 1;
    }

    .popover-card .lyo {
        display: flex;
        align-items: center;
        cursor: pointer;
        padding: 6px;
        border-radius: 6px;
    }

    .popover-card .lyo:hover {
        background-color: rgba(245, 245, 245, 1);
    }

    .popover-card .lyo>img {
        display: block;
        width: 2rem;
        max-width: 20px;
        height: 2rem;
        max-height: 20px;
        margin-right: 10px;
    }

    .popover-card .lyo>div {
        font-weight: 400;
        font-size: clamp(8px, 1.6rem, 16px);
        font-family: 'MyCustomFont', sans-serif;
        color: #333333;
    }

    .popover-card .lyo>div>div:nth-of-type(2) {
        font-weight: 400;
        font-size: clamp(8px, 1.4rem, 14px);
        font-family: 'MyCustomFont', sans-serif;
        color: #999999;
    }
}

@media (max-width: 768px) {
    .heart {
        width: 100%;
        margin: 0 clamp(4px, 1.4rem, 14px);
        z-index: 999;
        display: flex;
        align-items: center;
    }

    .heart .menu {
        flex: 1;
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    /* logo */
    .heart .menu .logo {
        display: flex;
        align-items: center;
        cursor: pointer;
    }

    .heart .menu .logo .img {
        width: 3rem;
        max-width: 30px;
        height: 3rem;
        max-height: 30px;
    }

    .heart .menu .logo>div {
        font-weight: 600;
        font-size: clamp(6px, 1.8rem, 18px);
        font-family: 'MyCustomFont', sans-serif;
        margin-left: 6px;
    }

    .heart .menu .logo>div:nth-of-type(2) {
        color: rgba(25, 73, 255, 1);
        font-size: clamp(6px, 1.2rem, 12px);
        align-self: start;
        margin-left: 0;
    }

    .heart .menu .bill-fare {
        display: flex;
        align-items: center;
    }

    .heart .menu .bill-fare .residual-integral {
        display: flex;
        align-items: center;
        margin-right: 2rem;
    }

    .heart .menu .bill-fare .residual-integral>div:nth-of-type(1) {
        font-weight: 500;
        font-size: 1.6rem;
        font-family: 'MyCustomFont', sans-serif;
    }

    .heart .menu .bill-fare .residual-integral>img {
        display: block;
        width: 2.2rem;
        height: 2.2rem;
        margin: 0 3px;
    }

    .heart .menu .bill-fare .anticon {
        font-size: 2.6rem;
    }

    /* share */
    .heart .menu .bill-fare .share-but {
        display: flex;
        justify-content: center;
        align-items: center;
        transition: color 0.3s ease;
        border-radius: 20px;
        width: 2.2rem;
        height: 2.2rem;
        margin-right: 16px;
    }

    .heart .menu .bill-fare .share-but .share-icon {
        font-size: 1.6rem;
    }

    /* 导航栏 */
    .header-drawer .ant-drawer-header {
        padding: 0;
    }

    .header-drawer .header-menu {
        height: 6.6rem;
        max-height: 66px;
        padding: 0 clamp(4px, 1.4rem, 14px);
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .header-drawer .header-menu>.img {
        width: 3rem;
        max-width: 30px;
        height: 3rem;
        max-height: 30px;
        display: block;
    }

    .header-drawer .header-menu .anticon {
        font-size: 2.6rem;
    }

    .header-drawer .ant-drawer-body {
        padding: 0;
    }

    .header-drawer .ant-menu {
        background-color: inherit;
        border: none !important;
        padding: 14px;
    }

    .header-drawer .ant-menu .ant-menu-item {
        font-size: clamp(12px, 2rem, 20px);
        font-family: 'MyCustomFont', sans-serif;
        font-weight: 600;
        color: #000000;
        width: 100%;
        height: 4rem;
        max-height: 40px;
        border-radius: 8px;
        margin: 0;
        border-bottom: 1px solid rgba(5, 5, 5, 0.06);
    }

    .header-drawer .ant-menu .ant-menu-item::after {
        content: none;
    }

    .header-drawer .ant-menu .ant-menu-item:active {
        background-color: rgba(235, 235, 235, 1) !important;
        color: rgba(25, 73, 255, 1);
    }

    .header-drawer .ant-menu .ant-menu-item .ant-menu-title-content {
        width: 100%;
        display: flex;
        align-items: center;
    }

    .header-drawer .ant-menu .ant-menu-item .popover-but {
        width: 100%;
        height: 4rem;
        max-height: 40px;
        border-radius: 20px;
        font-size: clamp(12px, 1.6rem, 16px);
        font-family: 'MyCustomFont', sans-serif;
        font-weight: 600;
        display: flex;
        align-items: center;
    }

    .header-drawer .ant-menu .ant-menu-item:active #header-button>span {
        color: rgba(25, 73, 255, 1);
    }

    .header-drawer .ant-menu .ant-menu-item span span {
        font-size: clamp(12px, 1.6rem, 16px);
        font-family: 'MyCustomFont', sans-serif;
        font-weight: 600;
    }

    .header-drawer .ant-menu .ant-menu-item-selected {
        background-color: transparent;
    }

    .header-drawer .ant-menu .ant-menu-item-selected span span span {
        color: rgba(255, 255, 255, 1);
    }

    .app-popover {
        width: 100%;
    }

    .app-popover .ant-popover-inner {
        width: calc(100% - 28px);
        padding: 14px;
    }

    .popover-card .lyo {
        display: flex;
        align-items: center;
        cursor: pointer;
        padding: 6px 16px;
        border-radius: 8px;
        margin: 4px 0;
        border-bottom: 1px solid rgba(5, 5, 5, 0.06);
    }

    .popover-card .lyo:active {
        background-color: rgba(235, 235, 235, 1);
    }

    .popover-card .lyo>img {
        display: block;
        width: 2rem;
        max-width: 20px;
        height: 2rem;
        max-height: 20px;
        margin-right: 10px;
    }

    .popover-card .lyo>div {
        font-weight: 400;
        font-size: clamp(8px, 1.6rem, 16px);
        font-family: 'MyCustomFont', sans-serif;
        color: #333333;
    }

    .popover-card .lyo>div>div:nth-of-type(2) {
        font-weight: 400;
        font-size: clamp(8px, 1.4rem, 14px);
        font-family: 'MyCustomFont', sans-serif;
        color: #999999;
    }

    .header-drawer .userAndMenu {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .header-drawer .userAndMenu .login {
        font-weight: 600;
        font-size: clamp(12px, 1.6rem, 16px);
        font-family: 'MyCustomFont', sans-serif;
        background-color: rgba(25, 73, 255, 1);
        color: rgba(255, 255, 255, 1);
        font-style: normal;
        display: flex;
        justify-content: left;
        align-items: center;
        transition: color 0.3s ease;
        border-radius: 8px;
        width: 100%;
        height: clamp(6px, 4rem, 40px);
        margin: 0 14px;
        padding: 4px 16px;
    }

    .header-drawer .userAndMenu .login:active {
        color: rgba(255, 255, 255, 1);
        background: rgba(25, 73, 255, 0.5);
        font-weight: 600;
        color: #000000;
    }

    .header-drawer .userAndMenu .signup {
        width: 11.3rem;
        max-width: 113px;
        min-width: 70px;
        height: 4.4rem;
        max-height: 44px;
        min-height: 26px;
        border-radius: clamp(13px, 2.2rem, 22px);
        font-family: 'MyCustomFont', sans-serif;
        font-weight: 500;
        font-size: clamp(14px, 1.6rem, 20px);
        font-style: normal;
        color: rgba(255, 255, 255, 1);
        background-color: rgba(13, 12, 34, 1);
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .header-drawer .userAndMenu .signup:active {
        font-weight: 600;
        background-color: rgba(13, 12, 34, 0.9);
    }

    .header-drawer .userAndMenu .user {
        width: 100%;
        display: flex;
        align-items: center;
        font-family: 'MyCustomFont', sans-serif;
        margin: 0 14px;
        border-bottom: 1px solid rgba(5, 5, 5, 0.06);
    }

    .header-drawer .userAndMenu .user:active {
        background-color: rgba(235, 235, 235, 1);
    }

    .header-drawer .userAndMenu .user>.img {
        width: 4.4rem;
        max-width: 44px;
        height: 4.4rem;
        max-height: 44px;
        margin: 8px;
        background-color: rgba(168, 107, 204, 1);
        color: rgba(255, 255, 255, 1);
        margin-right: clamp(5px, 1.3rem, 13px);
    }

    .header-drawer .userAndMenu .user .user-name {
        font-family: 'MyCustomFont', sans-serif;
        font-weight: 500;
        font-size: clamp(10px, 1.4rem, 14px);
        color: #333333;
        font-style: normal;
    }

    .user-dropdown {
        width: 100%;
    }

    .user-dropdown .ant-dropdown-menu {
        width: calc(100% - 28px);
        padding: 14px;
    }

    .user-dropdown .ant-dropdown-menu .ant-dropdown-menu-item {
        font-family: 'MyCustomFont', sans-serif;
        font-weight: 500;
        font-size: clamp(10px, 1.4rem, 14px);
        border-radius: 8px;
        text-align: justify;
        font-style: normal;
        margin: 4px 0;
        border-bottom: 1px solid rgba(5, 5, 5, 0.06);
    }

    .user-dropdown .ant-dropdown-menu .ant-dropdown-menu-item:active {
        background-color: rgba(235, 235, 235, 1);
    }
}