@media (min-width: 768px) {
    .content-four .content-four-one {
        background: linear-gradient(110deg, rgba(255, 255, 255, 0.00) 0%, #FEB2FF 99%);
        max-width: 100%;
        font-family: 'MyCustomFont', sans-serif;
    }

    .content-four .content-four-two {
        background: linear-gradient(110deg, #ADABF5 0%, rgba(255, 255, 255, 0.00) 99%);
        max-width: 100%;
        font-family: 'MyCustomFont', sans-serif;
    }

    .content-four .content-four-three {
        background: linear-gradient(110deg, rgba(255, 255, 255, 0.00) 0%, #FEB2FF 99%);
        max-width: 100%;
        font-family: 'MyCustomFont', sans-serif;
    }

    .content-four .content-four-four {
        background: rgba(247, 247, 251, 1);
        max-width: 100%;
        font-family: 'MyCustomFont', sans-serif;
    }

    .content-four .content-four-three .content-row,
    .content-four .content-four-two .content-row,
    .content-four .content-four-one .content-row {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        width: 144rem;
        max-width: 1440px;
        margin: 0 auto;
    }

    .content-four .content-four-three .content-row .content-row-img,
    .content-four .content-four-two .content-row .content-row-img,
    .content-four .content-four-one .content-row .content-row-img {
        width: 72rem;
        aspect-ratio: 310 / 181;
        max-width: 50%;
        position: relative;
        border-radius: 16px;
        overflow: hidden;
        margin: clamp(40px, 8rem, 80px) 0;
        user-select: none;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
    }

    .content-four .content-four-three .content-row .content-row-text,
    .content-four .content-four-two .content-row .content-row-text,
    .content-four .content-four-one .content-row .content-row-text {
        width: 72rem;
        max-width: 50%;
        padding-left: clamp(20px, 4rem, 40px);
        margin: clamp(40px, 8rem, 80px) 0;
        user-select: none;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        display: flex;
        flex-flow: column;
        justify-content: space-between;
        font-family: 'MyCustomFont', sans-serif;
    }

    .content-four .content-four-three .content-row-img>img,
    .content-four .content-four-two .content-row-img>img,
    .content-four .content-four-one .content-row-img>img {
        display: block;
        width: 90rem;
        max-width: 100%;
        height: auto;
        position: absolute;
        user-select: none;
        -webkit-user-drag: none;
        pointer-events: none;
    }

    .content-four .content-four-three .content-row-text .text>h2,
    .content-four .content-four-two .content-row-text .text>h2,
    .content-four .content-four-one .content-row-text .text>h2 {
        font-size: clamp(26px, 5rem, 50px);
        font-weight: 600;
        color: #2b2b2b;
        margin-bottom: clamp(10px, 3rem, 30px);
    }

    .content-four .content-four-three .content-row-text .text>div>p,
    .content-four .content-four-two .content-row-text .text>div>p,
    .content-four .content-four-one .content-row-text .text>div>p {
        font-size: clamp(6px, 2rem, 20px);
        font-weight: 400;
        color: #2b2b2b;
        margin-bottom: clamp(10px, 3rem, 30px);
    }

    .content-four .content-four-three .content-row-text .text>div>p:nth-of-type(1),
    .content-four .content-four-two .content-row-text .text>div>p:nth-of-type(1),
    .content-four .content-four-one .content-row-text .text>div>p:nth-of-type(1) {
        font-size: clamp(6px, 2.6rem, 26px);
        font-weight: 600;
        color: #2b2b2b;
        margin-bottom: clamp(10px, 3rem, 30px);
    }

    .content-four .content-four-three .content-row-text .text>div>p:nth-of-type(2),
    .content-four .content-four-two .content-row-text .text>div>p:nth-of-type(2),
    .content-four .content-four-one .content-row-text .text>div>p:nth-of-type(2) {
        letter-spacing: 0.8px;
    }

    .content-four .content-row-text .but {
        width: 16.2rem;
        height: 4.2rem;
        max-width: 162px;
        max-height: 42px;
        background: rgba(25, 73, 255, 1);
        font-family: 'MyCustomFont', sans-serif;
        font-weight: 500;
        font-size: clamp(6px, 1.6rem, 16px);
        color: #FFFFFF;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .content-four .content-row-text .but:active,
    .content-four .content-row-text .but:hover {
        background: rgba(25, 73, 255, 0.5);
    }

    .content-four .content-four-four .content-row {
        width: 144rem;
        max-width: 1440px;
        margin: 0 auto;
        display: flex;
        flex-flow: column;
        align-items: center;
        font-family: 'MyCustomFont', sans-serif;
        overflow: hidden;
    }

    .content-four .content-four-four .content-row .title {
        font-size: clamp(26px, 5rem, 50px);
        font-weight: 600;
        color: #2b2b2b;
        margin-top: clamp(20px, 11.2rem, 112px);
    }

    .content-four .content-four-four .content-row .alter {
        font-size: clamp(18px, 2rem, 20px);
        font-weight: 400;
        color: #2b2b2b;
        margin-top: clamp(10px, 3rem, 30px);
    }

    .content-four .content-four-four .content-row .content {
        width: 100%;
        height: 50rem;
        max-height: 500px;
        margin-top: clamp(50px, 10rem, 100px);
        margin-bottom: clamp(50px, 10rem, 100px);
        background: linear-gradient(135deg, rgba(175, 173, 255, 1) 0%, rgba(253, 215, 244, 1) 100%);
        border-radius: 36px;
        display: flex;
        justify-content: center;
        padding: clamp(10px, 3rem, 30px) 0;
    }

    .content-four .content-four-four .content .image-box {
        width: 100%;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
    }

    .content-four .content-four-four .content .image-box .box {
        width: 32.5rem;
        max-width: 325px;
        height: 43rem;
        max-height: 430px;
        background-color: rgba(255, 255, 255, 1);
        border-radius: 16px;
        padding: 16px;
        overflow: hidden;
    }

    .content-four .content-four-four .content .image-box .icon {
        font-size: clamp(20px, 4rem, 40px);
        color: rgba(255, 255, 255, 1);
    }

    .content-four .content-four-four .content .image-box .box>img {
        width: 100%;
        height: auto;
        display: block;
    }

    .content-four .content-four-four .content .image-box .box>div {
        width: 100%;
        margin-top: clamp(10px, 2rem, 20px);
        font-size: clamp(12px, 2rem, 20px);
        font-weight: 400;
        color: rgba(0, 0, 0, 1);
        word-break: break-all;
        text-align: center;
    }
}

@media (max-width: 768px) {
    .content-four .content-four-one {
        background: linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, #FEB2FF 99%);
        max-width: 100%;
        padding: 0 14px;
        font-family: 'MyCustomFont', sans-serif;
    }

    .content-four .content-four-two {
        background: linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, #ADABF5 99%);
        max-width: 100%;
        padding: 0 14px;
        font-family: 'MyCustomFont', sans-serif;
    }

    .content-four .content-four-three {
        background: linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, #FEB2FF 99%);
        max-width: 100%;
        padding: 0 14px;
        font-family: 'MyCustomFont', sans-serif;
    }

    .content-four .content-four-four {
        background: rgba(247, 247, 251, 1);
        max-width: 100%;
        padding: 0 14px;
        font-family: 'MyCustomFont', sans-serif;
    }

    .content-four .content-four-three .content-row,
    .content-four .content-four-two .content-row,
    .content-four .content-four-one .content-row {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-flow: column;
        width: 100%;
    }

    .content-four .content-four-two .content-row {
        flex-flow: column-reverse;
    }

    .content-four .content-four-three .content-row .content-row-img,
    .content-four .content-four-two .content-row .content-row-img,
    .content-four .content-four-one .content-row .content-row-img {
        width: 100%;
        aspect-ratio: 310 / 181;
        position: relative;
        border-radius: 16px;
        overflow: hidden;
        margin-top: 4rem;
        user-select: none;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
    }

    .content-four .content-four-three .content-row .content-row-text,
    .content-four .content-four-two .content-row .content-row-text,
    .content-four .content-four-one .content-row .content-row-text {
        width: 100%;
        margin-top: 4rem;
        user-select: none;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        display: flex;
        flex-flow: column;
        justify-content: space-between;
    }

    .content-four .content-four-three .content-row-img>img,
    .content-four .content-four-two .content-row-img>img,
    .content-four .content-four-one .content-row-img>img {
        display: block;
        width: 100%;
        height: auto;
        position: absolute;
        user-select: none;
        -webkit-user-drag: none;
        pointer-events: none;
    }

    .content-four .content-four-three .content-row-text .text>h2,
    .content-four .content-four-two .content-row-text .text>h2,
    .content-four .content-four-one .content-row-text .text>h2 {
        font-size: 1.8rem;
        font-weight: 600;
        color: #2b2b2b;
        margin: 0;
        margin-bottom: 2rem;
    }

    .content-four .content-four-three .content-row-text .text>div>p,
    .content-four .content-four-two .content-row-text .text>div>p,
    .content-four .content-four-one .content-row-text .text>div>p {
        font-size: 1.2rem;
        line-height: 1.8rem;
        font-weight: 400;
        color: rgba(153, 153, 153, 1);
        margin: 0;
        margin-bottom: 2rem;
    }

    .content-four .content-four-three .content-row-text .text>div>p:nth-of-type(1),
    .content-four .content-four-two .content-row-text .text>div>p:nth-of-type(1),
    .content-four .content-four-one .content-row-text .text>div>p:nth-of-type(1) {
        font-size: 1.4rem;
        font-weight: 600;
        color: #2b2b2b;
    }

    .content-four .content-row-text .but {
        margin: 2rem 0;
        width: 16.2rem;
        height: 4.2rem;
        background: rgba(25, 73, 255, 1);
        font-family: 'MyCustomFont', sans-serif;
        font-weight: 500;
        font-size: 1.6rem;
        color: #FFFFFF;
        align-self: flex-end;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 8px;
    }

    .content-four .content-row-text .but:active {
        background: rgba(25, 73, 255, 0.5);
    }

    .content-four .content-four-four .content-row {
        width: 100%;
        margin: 0 auto;
        display: flex;
        flex-flow: column;
        font-family: 'MyCustomFont', sans-serif;
        overflow: hidden;
    }

    .content-four .content-four-four .content-row .title {
        font-size: 1.8rem;
        font-weight: 600;
        color: #2b2b2b;
        margin: 4rem 0 2rem;
    }

    .content-four .content-four-four .content-row .alter {
        font-size: 1.4rem;
        font-weight: 600;
        color: #2b2b2b;
        margin-bottom: 2rem;
    }

    .content-four .content-four-four .content-row .content {
        width: 100%;
        margin-top: 1rem;
        margin-bottom: 4rem;
        background: linear-gradient(135deg, rgba(175, 173, 255, 1) 0%, rgba(253, 215, 244, 1) 100%);
        border-radius: 8px;
        display: flex;
        justify-content: center;
        padding: 1rem;
    }

    .content-four .content-four-four .content .image-box {
        width: 100%;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
    }

    .content-four .content-four-four .content .image-box .box {
        width: 32.5rem;
        background-color: rgba(255, 255, 255, 1);
        border-radius: 8px;
        padding: 8px;
        overflow: hidden;
    }

    .content-four .content-four-four .content .image-box .icon {
        font-size: 2rem;
        color: rgba(255, 255, 255, 1);
    }

    .content-four .content-four-four .content .image-box .box>img {
        width: 100%;
        height: auto;
        display: block;
    }

    .content-four .content-four-four .content .image-box .box>div {
        width: 100%;
        margin-top: 1rem;
        font-size: clamp(2px, 0.6rem, 10px);
        font-weight: 400;
        color: rgba(0, 0, 0, 1);
        word-break: break-all;
        text-align: center;
    }
}