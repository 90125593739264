@keyframes spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

@media (min-width: 768px) {
    .faceid-bg {
        min-height: calc(100vh - clamp(10px, 29.6rem, 296px));
        background-color: rgba(239, 238, 238, 1);
        overflow: hidden;
    }

    .faceid {
        width: 144rem;
        margin: 1rem auto 5rem;
        max-width: 1440px;
    }

    /* title */
    .faceid .faceid-header {
        width: 100%;
        height: 1.6rem;
        max-height: 16px;
        font-size: clamp(6px, 1.8rem, 18px);
        font-family: 'MyCustomFont', sans-serif;
        margin: clamp(6px, 3.6rem, 36px) 0 0 0;
        display: flex;
        align-items: center;
    }

    .faceid .faceid-header>img {
        width: 1.8rem;
        height: 1.8rem;
        max-width: 18px;
        max-height: 18px;
        margin-right: clamp(6px, 1rem, 10px);
        cursor: pointer;
    }

    .faceid .faceid-header>div {
        height: 2rem;
        max-height: 20px;
        font-family: 'MyCustomFont', sans-serif;
        font-weight: 400;
        font-size: clamp(6px, 1.8rem, 18px);
        color: #333333;
        display: flex;
        align-items: center;
        font-style: normal;
        margin-right: clamp(6px, 1rem, 10px);
        cursor: pointer;
    }

    .faceid .faceid-header>span {
        margin-right: 3px;
    }

    /* form */
    .faceid .faceid-box {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-top: clamp(6px, 4.8rem, 48px);
    }

    .faceid .prompt {
        width: 75.8rem;
        max-width: 758px;
        height: 64rem;
        max-height: 640px;
        background: rgba(255, 255, 255, 1);
        border: 1px solid rgba(231, 231, 231, 1);
        box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.1);
        border-radius: 16px;
        padding: clamp(6px, 2.5rem, 25px) clamp(6px, 2rem, 20px) clamp(6px, 3rem, 30px);
    }

    .faceid .prompt .form {
        width: 100%;
        overflow: hidden;
    }

    .faceid .upload {
        display: flex;
        justify-content: space-between;
        border-radius: 16px;
    }

    .faceid .upload .template {
        width: 34.6rem;
        max-width: 346px;
    }

    .faceid .upload .action-box {
        width: 35.2rem;
        max-width: 352px;
    }

    /* form title */
    .faceid .upload .action-box .title,
    .faceid .upload .template .title {
        height: 2.6rem;
        max-height: 26px;
        min-height: 18px;
        font-family: 'MyCustomFont', sans-serif;
        font-weight: 500;
        font-size: clamp(6px, 1.8rem, 18px);
        color: #000000;
    }

    /* form action-box template */
    .faceid .upload .action-box .action,
    .faceid .upload .template .slideshow {
        position: relative;
        height: 46.6rem;
        max-height: 466px;
        margin: clamp(6px, 1.4rem, 14px) 0;
        display: flex;
        overflow: hidden;
    }

    .faceid .upload .template-image .slick-list,
    .faceid .upload .template-image .ant-carousel,
    .faceid .upload .template-image .slick-slider {
        width: 100%;
    }

    .faceid .upload .template .template-image {
        width: 100%;
        height: auto;
        border-radius: 16px;
        overflow: hidden;
        pointer-events: none;
    }

    .faceid .upload .template-image .template-item-image {
        display: block;
        width: 100%;
        height: 46.6rem;
        max-height: 466px;
        pointer-events: none;
    }

    .faceid .upload .template .exa-img {
        width: 100%;
        display: flex;
        justify-content: center;
        position: absolute;
        bottom: clamp(6px, 1.4rem, 14px);
    }

    .faceid .upload .template .exa-img img {
        display: block;
        width: 7.5rem;
        max-width: 75px;
        height: auto;
        margin: 0 clamp(2px, 0.6rem, 6px);
        border-radius: 8px;
        border: 1px solid transparent;
        pointer-events: none;
    }

    .faceid .upload .action-box .action {
        position: relative;
        overflow: hidden;
        display: flex;
        flex-flow: column;
    }

    .faceid .upload .action-box .action .notice {
        position: absolute;
        top: 16px;
        right: 16px;
        font-size: clamp(6px, 2.6rem, 26px);
    }

    .faceid .upload .action .upload-item {
        width: 100%;
        height: 100%;
        border-radius: 16px;
        border: 1px solid #979797;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        margin-bottom: 0;
    }

    .faceid .upload .action .upload-item .ant-upload-list-item-error .anticon-picture,
    .faceid .upload .action .upload-item .ant-upload-list-item-error .ant-upload-list-item-name,
    .faceid .upload .action .upload-item .ant-upload-list-item-uploading {
        display: none;
    }

    .faceid .upload-item .upload-source {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .faceid .upload-item .upload-source .ant-upload-select {
        width: 8.6rem;
        max-width: 86px;
        height: 8.6rem;
        max-height: 86px;
        margin: clamp(30px, 5rem, 50px) 0;
    }

    .faceid .upload-item .ant-form-item-explain-error {
        text-align: center;
    }

    .faceid .ant-upload-select button {
        border: none;
        background: none;
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }

    .faceid .ant-upload-select button>div {
        font-size: clamp(6px, 1.4rem, 14px);
        font-family: 'MyCustomFont', sans-serif;
        margin-top: .8rem;
    }

    .faceid .upload .action .ant-form-item-row,
    .faceid .upload .action .ant-form-item-row .ant-form-item-control,
    .faceid .upload .action .ant-form-item-row .ant-form-item-control .ant-form-item-control-input,
    .faceid .upload .action .ant-form-item-row .ant-form-item-control-input .ant-form-item-control-input-content,
    .faceid .upload .action .ant-form-item-row .ant-form-item-control-input .ant-form-item-control-input-content .upload-source,
    .faceid .upload .action .ant-form-item-row .ant-form-item-control-input .upload-source .ant-upload-list-picture-card,
    .faceid .upload .action .ant-form-item-row .ant-form-item-control-input .upload-source .ant-upload-list-picture-card .ant-upload-list-item-container,
    .faceid .upload .action .ant-form-item-has-success .ant-form-item-row,
    .faceid .ant-form-item-has-success .ant-form-item-control-input,
    .faceid .ant-form-item-has-success .ant-form-item-control-input .ant-form-item-control-input-content,
    .faceid .ant-form-item-has-success .upload-source,
    .faceid .ant-form-item-has-success .upload-source .ant-upload-list,
    .faceid .ant-form-item-has-success .upload-source .ant-upload-list .ant-upload-list-item-container,
    .faceid .ant-form-item-has-success .upload-source .ant-upload-list .ant-upload-list-item-container .ant-upload-list-item {
        width: 100%;
        height: 100%;
    }

    .faceid .upload .action .ant-form-item-row .ant-form-item-control {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-flow: column;
    }

    .faceid .upload .action .ant-form-item-row .ant-form-item-control-input .upload-source .ant-upload-list-picture-card {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .faceid .ant-upload-list .ant-upload-list-item-actions {
        display: flex;
        justify-content: space-around;
        flex-wrap: nowrap;
    }

    .faceid .ant-upload-list .ant-tooltip {
        display: none;
    }

    .faceid .ant-upload-list .ant-upload-list-item {
        border: none !important;
        color: rgba(217, 217, 217, 1) !important;
    }

    .faceid .ant-upload-list-text {
        display: none;
    }

    .faceid .ant-btn[disabled] {
        background-color: white
    }

    /* form footer */
    .faceid .upload .action-box .footer,
    .faceid .upload .template .footer {
        width: 100%;
        height: 6.4rem;
        max-height: 64px;
    }

    .faceid .upload .template .footer {
        font-family: 'MyCustomFont', sans-serif;
        color: rgba(0, 0, 0, 1);
        font-size: clamp(6px, 1.8rem, 18px);
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .faceid .upload .template .footer>div:nth-of-type(1) {
        display: flex;
        align-items: center;
    }

    .faceid .upload .template .footer>div:nth-of-type(1)>div:nth-of-type(1) {
        font-weight: 500;
        font-size: clamp(6px, 1.6rem, 16px);
        line-height: clamp(6px, 1.6rem, 16px);
        color: #000000;
        white-space: nowrap;
    }

    .faceid .upload .template .footer>div:nth-of-type(2) {
        font-weight: 500;
        color: rgba(76, 82, 105, 1);
        font-size: clamp(6px, 1.2rem, 12px);
        white-space: nowrap;
    }

    .faceid .upload .template .footer>div:nth-of-type(1)>img {
        width: clamp(6px, 2rem, 20px);
        height: clamp(6px, 2rem, 20px);
        display: block;
        margin-right: 3px;
    }

    .faceid .upload .action-box .footer .but {
        width: 35.2rem;
        max-width: 352px;
        height: 100%;
        font-size: clamp(6px, 1.4rem, 14px);
        font-family: 'MyCustomFont', sans-serif;
        font-weight: 500;
        padding: 0;
        color: rgba(0, 0, 0, 1);
        background-color: rgba(255, 255, 255, 1);
        border: 1px solid rgba(151, 151, 151, 1);
        box-shadow: none;
        border-radius: 16px;
    }

    .faceid .upload .action-box .footer .but:disabled {
        background-color: rgba(230, 230, 230, 1);
        color: rgba(0, 0, 0, 1);
    }

    .faceid .upload .action-box .footer .but:disabled:hover {
        background-color: rgba(230, 230, 230, 1);
        color: rgba(255, 255, 255, 1);
    }

    .faceid .upload .action-box .footer .but:hover {
        background-color: rgba(25, 73, 255, 1);
        color: rgba(255, 255, 255, 1);
    }

    /* output-image */
    .faceid .output-image {
        width: 65.2rem;
        max-width: 652px;
        height: 64rem;
        max-height: 640px;
        position: relative;
        box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.1);
        border-radius: 16px;
        border: 1px solid #E7E7E7;
    }

    .faceid .output-image .output-image-bfc {
        width: 100%;
        height: 100%;
        border-radius: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
    }

    .faceid .output-image .output-image-bfc .container {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-between;
        padding: clamp(6px, 4.8rem, 48px) clamp(6px, 5.4rem, 54px);
    }

    .faceid .output-image .output-image-bfc .container .main-image {
        width: 42rem;
        max-width: 420px;
        height: 54rem;
        max-height: 540px;
        border-radius: 16px;
        overflow: hidden;
    }

    .faceid .output-image .output-image-bfc .container .thumbnail-container {
        display: flex;
        flex-flow: column;
        gap: clamp(6px, 3.8rem, 30px);
    }

    .faceid .output-image .output-image-bfc .container .thumbnail {
        width: calc(9.1rem - 4px);
        max-width: 87px;
        height: calc(11.7rem - 4px);
        max-height: 113px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }

    .faceid .output-image .output-image-bfc .container .thumbnail>div {
        border-radius: 16px;
        overflow: hidden;
    }

    .faceid .output-image .output-image-bfc .container .thumbnail>div>div {
        pointer-events: none;
    }

    .faceid .output-image .output-image-bfc .container .thumbnail>svg {
        width: 50%;
    }

    .faceid .output-image .loading-box,
    .faceid .output-image .noimage-box {
        width: 17rem;
        max-width: 170px;
        height: 17rem;
        max-height: 170px;
        background-color: rgba(0, 0, 0, 1);
        border-radius: clamp(20px, 4rem, 40px);
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .faceid .output-image .loading-box .noimage,
    .faceid .output-image .noimage-box .noimage {
        display: block;
        width: 9rem;
        max-width: 90px;
        height: 9rem;
        max-height: 90px;
    }

    .faceid .output-image .loading-box {
        position: relative;
    }

    .faceid .output-image .loading-box .noimage {
        animation: spin 2s linear infinite;
    }

    .faceid .output-image .loading-box>div {
        font-size: clamp(6px, 1.6rem, 16px);
        font-family: 'MyCustomFont', sans-serif;
        font-weight: 400;
        color: rgba(113, 128, 150, 1);
        white-space: nowrap;
        position: absolute;
        bottom: -100%;
        left: 50%;
        transform: translateX(-50%);
    }

    .faceid .output-image .error-box {
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;
    }

    .faceid .output-image .error-box>img {
        width: 23.8rem;
        max-width: 238px;
        height: 12.8rem;
        max-height: 128px;
        display: block;
    }

    .faceid .output-image .error-box>div {
        font-size: clamp(6px, 1.6rem, 16px);
        font-family: 'MyCustomFont', sans-serif;
        font-weight: 400;
        color: rgba(113, 128, 150, 1);
        margin-top: 10px;
    }
}

@media (max-width: 768px) {
    .faceid-bg {
        min-height: calc(100vh - clamp(10px, 10.2rem, 10.2px));
        background-color: rgba(239, 238, 238, 1);
        overflow: hidden;
    }

    .faceid {
        width: 100%;
        margin-bottom: 2rem;
        padding: 14px
    }

    /* title */
    .faceid .faceid-header {
        width: 100%;
        height: 1.6rem;
        font-family: 'MyCustomFont', sans-serif;
        margin: 1.8rem 0;
        display: flex;
        align-items: center;
    }

    .faceid .faceid-header>img {
        width: 1.4rem;
        height: 1.4rem;
        max-width: 14px;
        max-height: 14px;
        margin-right: clamp(6px, 1rem, 10px);
        cursor: pointer;
    }

    .faceid .faceid-header>div {
        height: 2rem;
        font-family: 'MyCustomFont', sans-serif;
        font-weight: 400;
        font-size: clamp(6px, 1.4rem, 14px);
        color: #333333;
        display: flex;
        align-items: center;
        font-style: normal;
        margin-right: clamp(6px, 1rem, 10px);
        cursor: pointer;
    }

    .faceid .faceid-header>span {
        margin-right: 3px;
    }

    /* form */
    .faceid .faceid-box {
        width: 100%;
        margin-top: 2rem;
    }

    .faceid .prompt {
        width: 100%;
        background: rgba(255, 255, 255, 1);
        border: 1px solid rgba(231, 231, 231, 1);
        box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.1);
        border-radius: 8px;
        padding: 14px;
    }

    .faceid .prompt .form {
        width: 100%;
        overflow: hidden;
    }

    .faceid .upload {
        border-radius: 8px;
    }

    .faceid .upload .template {
        width: 100%;
    }

    .faceid .upload .action-box {
        width: 100%;
    }

    /* form title */
    .faceid .upload .action-box .title,
    .faceid .upload .template .title {
        height: 2.6rem;
        line-height: 2.6rem;
        font-family: 'MyCustomFont', sans-serif;
        font-weight: 500;
        font-size: clamp(6px, 1.8rem, 18px);
        color: #000000;
    }

    /* form action-box template */
    .faceid .upload .template .slideshow {
        width: 100%;
        margin: 8px 0;
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;
    }

    .faceid .upload .template-image .slick-list,
    .faceid .upload .template-image .ant-carousel,
    .faceid .upload .template-image .slick-slider {
        width: 100%;
    }

    .faceid .upload .template .template-image {
        height: 23rem;
        aspect-ratio: 173/233;
        border-radius: 8px;
        overflow: hidden;
        pointer-events: none;
    }

    .faceid .upload .template-image .template-item-image {
        width: auto;
        height: 23rem;
        pointer-events: none;
    }

    .faceid .upload .template .exa-img {
        width: 100%;
        height: auto;
        display: flex;
        justify-content: center;
        margin-top: 1rem;
    }

    .faceid .upload .template .exa-img img {
        display: block;
        width: auto;
        height: 6rem;
        margin: 0 clamp(2px, 0.6rem, 6px);
        border-radius: 8px;
        border: 2px solid transparent;
        pointer-events: none;
    }

    .faceid .upload .action-box .action {
        position: relative;
        width: 100%;
        height: 20rem;
        margin: 8px 0;
        display: flex;
        justify-content: center;
        flex-flow: column;
        overflow: hidden;
    }

    .faceid .upload .action-box .action .notice {
        position: absolute;
        top: 16px;
        right: 16px;
        font-size: clamp(6px, 2.6rem, 26px);
    }

    .faceid .upload .action .upload-item {
        width: 100%;
        height: 100%;
        border-radius: 8px;
        border: 1px solid #979797;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        margin-bottom: 0;
    }

    .faceid .upload .action .upload-item .ant-upload-list-item-error .anticon-picture,
    .faceid .upload .action .upload-item .ant-upload-list-item-error .ant-upload-list-item-name,
    .faceid .upload .action .upload-item .ant-upload-list-item-uploading {
        display: none;
    }

    .faceid .upload-item .upload-source {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .faceid .upload-item .upload-source .ant-upload-select {
        width: 8.6rem;
        max-width: 86px;
        height: 8.6rem;
        max-height: 86px;
        margin: clamp(30px, 5rem, 50px) 0;
    }

    .faceid .upload-item .ant-form-item-explain-error {
        text-align: center;
    }

    .faceid .ant-upload-select button {
        border: none;
        background: none;
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }

    .faceid .ant-upload-select button>div {
        font-size: clamp(6px, 1.4rem, 14px);
        font-family: 'MyCustomFont', sans-serif;
        margin-top: .8rem;
    }

    .faceid .upload .action .ant-form-item-row,
    .faceid .upload .action .ant-form-item-row .ant-form-item-control,
    .faceid .upload .action .ant-form-item-row .ant-form-item-control .ant-form-item-control-input,
    .faceid .upload .action .ant-form-item-row .ant-form-item-control-input .ant-form-item-control-input-content,
    .faceid .upload .action .ant-form-item-row .ant-form-item-control-input .ant-form-item-control-input-content .upload-source,
    .faceid .upload .action .ant-form-item-row .ant-form-item-control-input .upload-source .ant-upload-list-picture-card,
    .faceid .upload .action .ant-form-item-row .ant-form-item-control-input .upload-source .ant-upload-list-picture-card .ant-upload-list-item-container,
    .faceid .upload .action .ant-form-item-has-success .ant-form-item-row,
    .faceid .ant-form-item-has-success .ant-form-item-control-input,
    .faceid .ant-form-item-has-success .ant-form-item-control-input .ant-form-item-control-input-content,
    .faceid .ant-form-item-has-success .upload-source,
    .faceid .ant-form-item-has-success .upload-source .ant-upload-list,
    .faceid .ant-form-item-has-success .upload-source .ant-upload-list .ant-upload-list-item-container,
    .faceid .ant-form-item-has-success .upload-source .ant-upload-list .ant-upload-list-item-container .ant-upload-list-item {
        width: 100%;
        height: 100%;
    }

    .faceid .upload .action .ant-form-item-row .ant-form-item-control {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-flow: column;
    }

    .faceid .upload .action .ant-form-item-row .ant-form-item-control-input .upload-source .ant-upload-list-picture-card {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .faceid .ant-upload-list .ant-upload-list-item-actions {
        display: flex;
        justify-content: space-around;
        flex-wrap: nowrap;
    }

    .faceid .ant-upload-list .ant-tooltip {
        display: none;
    }

    .faceid .ant-upload-list .ant-upload-list-item {
        border: none !important;
        color: rgba(217, 217, 217, 1) !important;
    }

    .faceid .ant-upload-list-text {
        display: none;
    }

    .faceid .ant-btn[disabled] {
        background-color: white
    }

    /* form footer */
    .faceid .upload .action-box .footer,
    .faceid .upload .template .footer {
        width: 100%;
        height: 6.4rem;
        max-height: 64px;
    }

    .faceid .upload .template .footer {
        font-family: 'MyCustomFont', sans-serif;
        color: rgba(0, 0, 0, 1);
        font-size: clamp(6px, 1.8rem, 18px);
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .faceid .upload .template .footer>div:nth-of-type(1) {
        display: flex;
        align-items: center;
    }

    .faceid .upload .template .footer>div:nth-of-type(1)>div:nth-of-type(1) {
        font-weight: 500;
        font-size: clamp(6px, 1.6rem, 16px);
        line-height: clamp(6px, 1.6rem, 16px);
        color: #000000;
        white-space: nowrap;
    }

    .faceid .upload .template .footer>div:nth-of-type(2) {
        font-weight: 500;
        color: rgba(76, 82, 105, 1);
        font-size: clamp(6px, 1.2rem, 12px);
        white-space: nowrap;
    }

    .faceid .upload .template .footer>div:nth-of-type(1)>img {
        width: clamp(6px, 2rem, 20px);
        height: clamp(6px, 2rem, 20px);
        display: block;
        margin-right: 3px;
    }

    .faceid .upload .action-box .footer .but {
        width: 100%;
        height: 100%;
        font-size: clamp(6px, 1.4rem, 14px);
        font-family: 'MyCustomFont', sans-serif;
        font-weight: 500;
        padding: 0;
        color: rgba(0, 0, 0, 1);
        background-color: rgba(255, 255, 255, 1);
        border: 1px solid rgba(151, 151, 151, 1);
        box-shadow: none;
        border-radius: 8px;
    }

    .faceid .upload .action-box .footer .but:disabled {
        background-color: rgba(230, 230, 230, 1);
        color: rgba(0, 0, 0, 1);
    }

    .faceid .upload .action-box .footer .but:disabled:active {
        background-color: rgba(230, 230, 230, 1);
        color: rgba(255, 255, 255, 1);
    }

    .faceid .upload .action-box .footer .but:active {
        background-color: rgba(25, 73, 255, 1);
        color: rgba(255, 255, 255, 1);
    }

    /* output-image */
    .faceid .output-image {
        width: 100%;
        height: 41rem;
        max-height: 640px;
        position: relative;
        box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.1);
        border-radius: 8px;
        border: 1px solid #E7E7E7;
        margin-top: 2rem;
    }

    .faceid .output-image .output-image-bfc {
        width: 100%;
        height: 100%;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        position: relative;
    }

    .faceid .output-image .output-image-bfc .container {
        width: 100%;
        height: 100%;
        padding: 0 8px;
        display: flex;
        flex-flow: column;
        justify-content: space-evenly;
        align-items: center;
    }

    .faceid .output-image .output-image-bfc .container .main-image {
        width: 100%;
        height: 30rem;
        border-radius: 8px;
        overflow: hidden;
        display: flex;
        justify-content: center;
    }

    .faceid .output-image .output-image-bfc .container .main-image .ant-image {
        border-radius: 8px;
        overflow: hidden;
    }

    .faceid .output-image .output-image-bfc .container .main-image img {
        height: 30rem;
        width: auto;
        border-radius: 8px;
        overflow: hidden;
    }

    .faceid .output-image .output-image-bfc .container .thumbnail-container {
        display: flex;
        justify-content: center;
    }

    .faceid .output-image .output-image-bfc .container .thumbnail-container>.thumbnail {
        margin: 0 clamp(2px, 0.6rem, 6px);
    }

    .faceid .output-image .output-image-bfc .container .thumbnail {
        min-width: 4.6rem;
        width: auto;
        height: 6rem;
    }

    .faceid .output-image .output-image-bfc .container .thumbnail img {
        width: auto;
        height: 6rem;
        cursor: pointer;
    }

    .faceid .output-image .output-image-bfc .container .thumbnail>div {
        border-radius: 8px;
        overflow: hidden;
    }

    .faceid .output-image .output-image-bfc .container .thumbnail>div>div {
        pointer-events: none;
    }

    .faceid .output-image .output-image-bfc .container .thumbnail>svg {
        width: 50%;
    }

    .faceid .output-image .loading-box,
    .faceid .output-image .noimage-box {
        width: 12rem;
        max-width: 120px;
        height: 12rem;
        max-height: 120px;
        background-color: rgba(0, 0, 0, 1);
        border-radius: clamp(14px, 2.8rem, 28px);
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .faceid .output-image .loading-box .noimage,
    .faceid .output-image .noimage-box .noimage {
        display: block;
        width: 6rem;
        max-width: 60px;
        height: 6rem;
        max-height: 60px;
    }

    .faceid .output-image .loading-box {
        position: relative;
    }

    .faceid .output-image .loading-box .noimage {
        animation: spin 2s linear infinite;
    }

    .faceid .output-image .loading-box>div {
        width: 150%;
        font-size: clamp(6px, 1.2rem, 12px);
        font-family: 'MyCustomFont', sans-serif;
        font-weight: 400;
        color: rgba(113, 128, 150, 1);
        text-align: center;
        position: absolute;
        bottom: -60%;
        left: 50%;
        transform: translateX(-50%);
    }

    .faceid .output-image .error-box {
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;
    }

    .faceid .output-image .error-box>img {
        width: 23.8rem;
        max-width: 238px;
        height: 12.8rem;
        max-height: 128px;
        display: block;
    }

    .faceid .output-image .error-box>div {
        font-size: clamp(6px, 1.2rem, 12px);
        font-family: 'MyCustomFont', sans-serif;
        font-weight: 400;
        color: rgba(113, 128, 150, 1);
        margin-top: 10px;
    }
}