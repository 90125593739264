@media (min-width: 768px) {
    .user-center {
        width: 100%;
        position: relative;
    }
    .user-center .bg {
        background-color: rgba(25, 73, 255, 1);
        width: 100%;
        height: 10rem;
        max-height: 100px;
        position: absolute;
        top: 0;
        left: 0;
        display: none;
    }
    .user-center .ant-tabs {
        width: 144rem;
        max-width: 1440px;
        margin: 0 auto;
        /* min-height: 90vh; */
    }
    .user-center .ant-tabs-nav {
        width: 100%;
        margin: 0;
        height: 0;
        /* height: 10rem;
        max-height: 100px; */
    }
    .user-center .ant-tabs-nav::before {
        display: none;
    }
    .user-center .ant-tabs-nav .ant-tabs-nav-wrap {
        width: 500px !important;
        margin: 0 auto;
    }
    .user-center .ant-tabs-nav .ant-tabs-nav-wrap::before {
        display: none;
    }
    .user-center .ant-tabs-nav .ant-tabs-nav-wrap::after {
        display: none;
    }
    .user-center .ant-tabs-nav .ant-tabs-tab-btn {
        font-family: 'MyCustomFont', sans-serif;
        font-weight: 500;
        font-size: 16px;
        color: rgba(255, 255, 255, 1);
        line-height: 19px;
        text-align: justify;
        font-style: normal;
    }
    .user-center .ant-tabs-nav::before {
        display: none !important;
    }
    .user-center .ant-tabs-nav .ant-tabs-tab-active > div {
        font-weight: 600;
        color: rgba(255, 255, 255, 1) !important;
    }
    .user-center .ant-tabs-nav .ant-tabs-ink-bar {
        width: 30% !important;
        background-color: rgba(255, 255, 255, 1);
        margin-bottom: clamp(5px, 1rem, 10px);
    }
    
    /* title */
    .user-center .user-header {
        width: 144rem;
        max-width: 1440px;
        margin: 0 auto;
    }
    .user-center .user-center-header {
        width: 100%;
        height: 1.6rem;
        max-height: 16px;
        font-size: clamp(6px, 1.8rem, 18px);
        font-family: 'MyCustomFont', sans-serif;
        margin: clamp(6px, 3.6rem, 36px) 0 0 0;
        display: flex;
        align-items: center;
    }
    .user-center .user-center-header > img {
        width: 1.8rem;
        height: 1.8rem;
        max-width: 18px;
        max-height: 18px;
        margin-right: clamp(6px, 1rem, 10px);
        cursor: pointer;
    }
    .user-center .user-center-header > div {
        height: 2rem;
        max-height: 20px;
        font-family: 'MyCustomFont', sans-serif;
        font-weight: 400;
        font-size: clamp(6px, 1.8rem, 18px);
        color: #333333;
        display: flex;
        align-items: center;
        font-style: normal;
        margin-right: clamp(6px, 1rem, 10px);
        cursor: pointer;
    }
    .user-center .user-center-header > span {
        margin-right: 3px;
    }
    /* .user-center .user-center-header > div:last-child {
        color: rgba(25, 73, 255, 1);
        font-weight: 600;
    } */
}
@media (max-width: 768px) {
    .user-center {
        width: 100%;
        position: relative;
        padding: 14px;
    }
    .user-center .bg {
        background-color: rgba(25, 73, 255, 1);
        width: 100%;
        height: 10rem;
        max-height: 100px;
        position: absolute;
        top: 0;
        left: 0;
        display: none;
    }
    .user-center .ant-tabs-nav {
        width: 100%;
        margin: 0;
        height: 0;
        /* height: 10rem;
        max-height: 100px; */
    }
    .user-center .ant-tabs-nav::before {
        display: none;
    }
    .user-center .ant-tabs-nav .ant-tabs-nav-wrap {
        width: 500px !important;
        margin: 0 auto;
    }
    .user-center .ant-tabs-nav .ant-tabs-nav-wrap::before {
        display: none;
    }
    .user-center .ant-tabs-nav .ant-tabs-nav-wrap::after {
        display: none;
    }
    .user-center .ant-tabs-nav .ant-tabs-tab-btn {
        font-family: 'MyCustomFont', sans-serif;
        font-weight: 500;
        font-size: 16px;
        color: rgba(255, 255, 255, 1);
        line-height: 19px;
        text-align: justify;
        font-style: normal;
    }
    .user-center .ant-tabs-nav::before {
        display: none !important;
    }
    .user-center .ant-tabs-nav .ant-tabs-tab-active > div {
        font-weight: 600;
        color: rgba(255, 255, 255, 1) !important;
    }
    .user-center .ant-tabs-nav .ant-tabs-ink-bar {
        width: 30% !important;
        background-color: rgba(255, 255, 255, 1);
        margin-bottom: clamp(5px, 1rem, 10px);
    }
    
    /* title */
    .user-center .user-header {
        width: 100%;
        margin: 0 auto;
    }
    .user-center .user-center-header {
        width: 100%;
        height: 1.6rem;
        font-family: 'MyCustomFont', sans-serif;
        margin: 1.8rem 0;
        display: flex;
        align-items: center;
    }
    .user-center .user-center-header > img {
        width: 1.4rem;
        height: 1.4rem;
        max-width: 14px;
        max-height: 14px;
        margin-right: clamp(6px, 1rem, 10px);
        cursor: pointer;
    }
    .user-center .user-center-header > div {
        height: 2rem;
        font-family: 'MyCustomFont', sans-serif;
        font-weight: 400;
        font-size: clamp(6px, 1.4rem, 14px);
        color: #333333;
        display: flex;
        align-items: center;
        font-style: normal;
        margin-right: clamp(6px, 1rem, 10px);
        cursor: pointer;
    }
    .user-center .user-center-header > span {
        margin-right: 3px;
    }
    /* .user-center .user-center-header > div:last-child {
        color: rgba(25, 73, 255, 1);
        font-weight: 600;
    } */
}