.footer-heart .logo {
    display: flex;
    align-items: center;
    font-family: 'MyCustomFont', sans-serif;
}
.footer-heart .left {
    display: flex;
    align-items: left;
    flex-direction: column;
    font-family: 'MyCustomFont', sans-serif;
}
.footer-heart button {
    font-family: 'MyCustomFont', sans-serif;
    color: white;
}

@media (min-width: 768px) {
    .footer-heart {
        height: 19.6rem;
        max-height: 196px;
        width: 144rem;
        max-width: 1440px;
        margin: auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .footer-heart .logo > img {
        width: 3.4rem;
        max-width: 34px;
        height: 3.4rem;
        max-height: 34px;
    }
    .footer-heart .logo > div {
        font-weight: 600;
        font-size: clamp(12px, 2rem, 20px);
        margin-left: 6px;
        margin-right: clamp(30px, 8rem, 80px);
    }
    .footer-heart .bottom {
        font-size: 1.6rem;
        font-weight: 400;
    }
}
@media (max-width: 768px) {
    .footer-heart {
        margin: 0 14px;
        width: calc(100% - 28px);
        position: relative;
        padding: 4px 0;
    }
    .footer-heart .logo > img {
        width: 2.2rem;
        max-width: 22px;
        height: 2.2rem;
        max-height: 22px;
    }
    .footer-heart .logo > div {
        font-weight: 600;
        font-size: clamp(6px, 1.6rem, 16px);
        margin-left: 6px;
    }
    .footer-heart > button {
        font-size: 12px;
        font-weight: 400;
        padding: 0;
        position: absolute;
        right: 0;
        top: 0;
        color: rgba(25, 73, 255, 1);
    }
}