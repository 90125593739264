@media (min-width: 768px) {
    .notice-modal .notice-modal-content {
        display: flex;
        justify-content: space-around;
    }
    .notice-modal .notice-modal-content > img {
        display: block;
        width: 376px;
        height: 376px;
        pointer-events: none;
        user-select: none;
        margin-bottom: 50px;
    }
    .notice-modal .notice-modal-content > div {
        width: 300px;
        height: auto;
    }
    .notice-modal .notice-modal-content .title {
        font-size: 20px;
        font-weight: 600;
        font-family: 'MyCustomFont', sans-serif;
        margin-bottom: 30px;
    }
    .notice-modal .notice-modal-content .alter {
        font-size: 16px;
        font-family: 'MyCustomFont', sans-serif;
        margin-bottom: 10px;
    }
    .notice-modal .notice-modal-content .text {
        font-size: 12px;
        font-weight: 400;
        font-family: 'MyCustomFont', sans-serif;
        margin-left: 20px;
        padding: 0;
    }
    .notice-modal .notice-modal-content .text > li:last-child {
        position: relative;
        list-style-type: none;
    }
    .notice-modal .notice-modal-content .text > li:last-child::before {
        content: '✖';
        position: absolute;
        left: -18px;
        color: red;
    }
}
@media (max-width: 768px) {
    .notice-modal .ant-modal-content {
        padding: 16px;
        border-radius: 8px;
    }
    .notice-modal .notice-modal-content {
        width: 100%;
        display: flex;
        justify-content: space-between;
    }
    .notice-modal .notice-modal-content > img {
        display: block;
        width: 49%;
        height: 49%;
        aspect-ratio: 1/1;
        pointer-events: none;
        user-select: none;
        -webkit-user-select: none;
        margin-bottom: 40px;
    }
    .notice-modal .notice-modal-content > div {
        width: 46%;
        height: auto;
    }
    .notice-modal .notice-modal-content .title {
        font-size: 0.8rem;
        font-weight: 600;
        font-family: 'MyCustomFont', sans-serif;
        margin-bottom: 8px;
    }
    .notice-modal .notice-modal-content .alter {
        font-size: 0.6rem;
        font-family: 'MyCustomFont', sans-serif;
        margin-bottom: 8px;
    }
    .notice-modal .notice-modal-content .text {
        font-size: 0.4rem;
        font-weight: 400;
        font-family: 'MyCustomFont', sans-serif;
        padding-left: 1rem;
    }
    .notice-modal .notice-modal-content .text > li:last-child {
        position: relative;
        list-style-type: none;
    }
    .notice-modal .notice-modal-content .text > li:last-child::before {
        content: '✖';
        position: absolute;
        left: clamp(-14px, -1rem, -4px);
        color: red;
    }
}