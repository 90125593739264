@media (min-width: 768px) {
    .applist {
        width: 144rem;
        max-width: 1440px;
        margin: 0 auto;
    }
    .applist .ant-tabs-nav {
        width: 400px;
        margin-top: 3.4rem;
    }
    .applist .ant-tabs-nav .ant-tabs-nav-wrap {
        display: flex;
        justify-content: space-between;
    }
    .applist .ant-tabs-nav .ant-tabs-tab-btn {
        font-family: 'MyCustomFont', sans-serif;
        font-weight: 600;
        font-size: 16px;
        color: rgba(153, 153, 153, 1);
        line-height: 19px;
        text-align: justify;
        font-style: normal;
    }
    .applist .ant-tabs-nav .ant-tabs-tab-active > div {
        color: rgba(25, 73, 255, 1) !important;
    }
    .applist .ant-tabs-nav .ant-tabs-ink-bar {
        background-color: rgba(25, 73, 255, 1);
    }
}
@media (max-width: 768px) {
    .applist {
        width: 100%;
        padding: 14px;
    }
}